//Internal
import "./index.css";
import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import axios from "axios";
import { follow, unfollow, updateProfile, checkUsernameAvailable } from "../../apis/userApi";
//Infinite Loader
import { FixedSizeGrid as Grid } from 'react-window';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
//External
import Modal from "react-modal";
import { formatNumber, userData } from "../../config/userData";

//Icons
import { IoClose } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { FaRegBookmark } from "react-icons/fa6";
import { ImLoop } from "react-icons/im";
import { MdLockOutline } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaPlay } from "react-icons/fa";
import DIAMOND_ICON from "../../assets/images/diamond.png";

//Favorite SubSection Layout
import { SongList, HashtagList, VideoList, UserList } from "../../components/songLayout";
import { songsData, usersData, } from "../../components/songLayout";

//IMPORT REDUX ELEMENTS
import { useSelector, useDispatch } from "react-redux";
import { fetchUserPersonalInfo } from "../../slices/userSlice";

//Modal Styles
const customStyles = {
    content: {
        width: "35%",
        height: "80%",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "20px",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
        transition: "opacity 2000ms ease-in-out",
    },
};

function Profile() {

    //REDUX SETUP TO DISPATCH DATA
    const dispatch = useDispatch();
    const { userPersonalNewInfo } = useSelector((state) => state.user);
    useEffect(() => {
        dispatch(fetchUserPersonalInfo());
    }, [dispatch]);
    console.log("userNewPersonInfoData", userPersonalNewInfo.user);


    const [active, setActive] = useState(false);
    const [currentTab, setCurrentTab] = useState('Followers'); // New state for managing tabs
    const [currentVideoTab, setCurrentVideoTab] = useState('Like'); // State for managing video sections

    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    //Handling Video/Likes/Favorites tab chnages.
    const handleVideoTabChange = (tab) => {
        setCurrentVideoTab(tab);
    };

    function handleOpen() {
        setActive(true);
    }

    const handleClose = () => {
        setUserPersonalInfo(prevInfo => {
            const updatedFollowing = prevInfo.user.Following.filter(follower => {
                const followerId = follower.UserRelationship.receiver_id;
                return followingStatus[followerId] !== false;
            });

            return {
                ...prevInfo,
                user: {
                    ...prevInfo.user,
                    Following: updatedFollowing
                }
            };
        });
        setActive(false);
    };

    //######################LIKE MODAL START##########################//
    const [likeModalActive, setlikeModalActive] = useState(false);

    function likeModalOpen() {
        setlikeModalActive(true)
    }

    function likeModalClose() {
        setlikeModalActive(false)
    }
    //######################LIKE MODAL END############################//


    //*************** Favorite Sub Tab Start **********************************/
    const [activeFavoriteSubTab, setActiveFavoriteSubTab] = useState("FavVideos");

    const handleFavoriteSubTabClick = (subTab) => {
        setActiveFavoriteSubTab(subTab);
    };

    //*************** Favorite Sub Tab End  **********************************/

    //*************** Porfolio Edit Start **********************************/
    const [profileActive, setProfileActive] = useState(false);

    function profileOpen() {
        setProfileActive(true);
    }
    function profileClose() {
        setProfileActive(false);
    }

    const [details, setDetails] = useState({
        nickname: '',
        username: '',
        gender: '',
        dob: new Date(),
        bio: '',
        country: '',
        emotionState: '',
        friendIntention: '',
        occupation: '',
        languageMastery: '',
        hobbies: '',
        person_height: '',
        person_weight: '',
        instagram: '',
        you_tube: '',
        facebook: '',
        twitter: ''
    });

    const [editMode, setEditMode] = useState(null);


    const handleSaveClick = async () => {

        if (editMode === 'username') {
            try {
                const isAvailable = await checkUsernameAvailable(details.username);
                if (!isAvailable) {
                    alert('Username is not available. Please choose another one.');
                    return;
                }
            } catch (error) {
                console.error('Error checking username availability:', error);
                alert('Failed to check username availability. Please try again.');
                return;
            }
        }
        const data = { name: editMode, value: details[editMode] };
        try {
            const result = await updateProfile(token, data);
            if (result) {
                alert('Detail updated successfully!');
                fetchUserDetails(); // Refresh user details after updating
                setEditMode(null);
            } else {
                alert('Failed to update detail. Please try again.');
            }
        } catch (error) {
            console.error('Error updating detail:', error);
            alert('Failed to update detail. Please try again.');
        }
    };

    const handleEditClick = (key) => {
        setEditMode(key);
    };

    const handleChange = (e, key) => {
        setDetails({
            ...details,
            [key]: e.target.value
        });
    };

    const handleDateChange = (date) => {
        setDetails(prevDetails => ({
            ...prevDetails,
            dob: date
        }));
    };


    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            style={{ margin: "7px", padding: "7px", borderRadius: "10px", border: "1px solid silver" }}
            onClick={onClick}
            value={value}
            ref={ref}
            readOnly
        />
    ));

    const renderDetailItem = (label, key, type = 'text') => (
        <div className="portfolio-item" key={key}>
            <span>{label}</span>
            {editMode === key ? (
                <span style={{ display: "flex", alignItems: "center", gap: "4px", color: "gray" }}>
                    {/* <input
                        style={{ margin: "7px", padding: "7px", borderRadius: "10px", border: "1px solid silver" }}
                        type="text"
                        value={details[key]}
                        onChange={(e) => handleChange(e, key)}
                    /> */}
                    {type === 'date' ? (
                        <DatePicker
                            selected={details[key]}
                            onChange={(date) => handleDateChange(date)}
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            customInput={<CustomInput />}

                        />
                    ) : (
                        key === 'gender' ? (
                            <select
                                style={{ margin: "7px", padding: "7px", borderRadius: "10px", border: "1px solid silver" }}
                                value={details[key]}
                                onChange={(e) => handleChange(e, key)}
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        ) : (
                            <input
                                style={{ margin: "7px", padding: "7px", borderRadius: "10px", border: "1px solid silver" }}
                                type={type}
                                value={details[key]}
                                onChange={(e) => handleChange(e, key)}
                            />)
                    )}
                    <button
                        style={{ backgroundColor: "#ddd", border: "none", borderRadius: "5px", padding: "8px", color: "black", width: "70px" }}
                        onClick={handleSaveClick}
                    >
                        Save
                    </button>
                </span>
            ) : (
                <span
                    style={{ display: "flex", alignItems: "center", gap: "4px", color: "gray" }}
                    onClick={() => handleEditClick(key)}
                >
                    {/* {details[key]}  */}
                    {key === 'dob' && details[key] instanceof Date ? details[key].toLocaleDateString() : details[key]} {/* Check if dob is a Date */}
                    <FaAngleRight />
                </span>
            )}
        </div>
    );



    //*************** Porfolio Edit End **********************************//

    //######################FETCHING DATA AXIOS##########################//
    const [userPersonalInfo, setUserPersonalInfo] = useState(null);
    const [followersDetails, setFollowersDetails] = useState([]);

    const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNrQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoic2h1YmhhbXMiLCJpYXQiOjE3MjA0MzkwMzl9.iWvCrA_B96wlv3uIkLFuBsjRFjAQJJ1XvbQ5VUw_xMI';

    const fetchUserDetails = async () => {
        try {
            const config = {
                headers: { Authorization: token }
            };

            const personalInfoResponse = await axios.get('https://api.dreamlived.com/users/getUserPersonalInfo', config);
            const videoData = personalInfoResponse?.data;
            setUserPersonalInfo(videoData);
            // console.log("videoData", videoData);
            // Update the details state with the fetched data
            setDetails({
                nickname: videoData.user.nickname || '',
                username: videoData.user.username || '',
                gender: videoData.user.gender || '',
                dob: videoData.user.dob ? new Date(videoData.user.dob).toLocaleDateString() : '',
                bio: videoData.user.bio || '',
                country: videoData.user.country || '',
                emotionState: videoData.user.emotion_state || '',
                friendIntention: videoData.user.making_friend_intention || '',
                occupation: videoData.user.occupation || '',
                languageMastery: videoData.user.language || '',
                hobbies: videoData.user.hobbies ? videoData.user.hobbies.join(', ') : '',
                person_height: videoData.user.person_height || '',
                person_weight: videoData.user.person_weight || '',
                instagram: videoData.user.instagram || '',
                you_tube: videoData.user.you_tube || '',
                facebook: videoData.user.facebook || '',
                twitter: videoData.user.twitter || ''
            });

            const followerIds = videoData.user.Followers || [];
            const followingIds = videoData.user.Following || [];
            const allIds = [...followerIds, ...followingIds].map(f => f.id); // Assuming Followers and Following contain objects with id

            if (allIds.length > 0) {
                const followersData = await Promise.all(allIds.map(async (id) => {
                    try {
                        const response = await axios.get(`https://api.dreamlived.com/users/infoById/${id}`, config);
                        return response.data;
                    } catch (error) {
                        console.error(`Error fetching details for user ID ${id}:`, error);
                        return null;
                    }
                }));
                setFollowersDetails(followersData.filter(follower => follower !== null));
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, [token]);



    //######################FETCHING DATA AXIOS##########################//


    //############## SCROLLING/THUMBNAIL LOGIC VIDEO SECTION ######################//
    const [currentPlayingVideoIndex, setCurrentPlayingVideoIndex] = useState(null);

    const Row = ({ columnIndex, rowIndex, style, video }) => {
        const videoIndex = rowIndex * 4 + columnIndex;

        if (!video) {
            return null;
        }

        const handleThumbnailClick = () => {
            setCurrentPlayingVideoIndex(videoIndex);
        };

        const handlePause = () => {
            setCurrentPlayingVideoIndex(null);
        };

        return (
            <div style={style} className="video-card">
                {currentPlayingVideoIndex === videoIndex ? (
                    <video
                        width="100%"
                        height="360px"
                        controls
                        style={{ objectFit: "none", backgroundColor: "black" }}
                        autoPlay
                        onPause={handlePause}
                    >
                        <source src={`https://dpcst9y3un003.cloudfront.net/${video.video}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <>
                        <img
                            src={`https://dpcst9y3un003.cloudfront.net/${video.thum}`}
                            alt="Thumbnail"
                            width="100%"
                            height="360px"
                            style={{ objectFit: "cover", cursor: "pointer" }}
                            onClick={handleThumbnailClick}
                        />
                        <div style={{ fontWeight: "bold", position: 'absolute', bottom: 0, color: 'white', padding: '0px 10px', borderRadius: '5px', display: "flex", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
                            <p><FaPlay style={{ color: "white" }} /> {formatNumber(video.view)}</p>
                            <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img src={DIAMOND_ICON} alt="DiamondIcon" style={{ width: "40px", height: "40px" }}></img>
                                {formatNumber(video.diamond_value)}
                            </p>
                        </div>
                    </>
                )}
            </div>
        );
    };

    //##############  SCROLLING/THUMBNAIL LOGIC VIDEO SECTION ######################//

    //############### FOLLOW/FOLLOWING DATA ######################//
    const [followingStatus, setFollowingStatus] = useState({}); // State to track following status
    useEffect(() => {
        const initFollowingStatus = () => {
            const status = {};
            userPersonalInfo?.user?.Following?.forEach(follower => {
                status[follower.UserRelationship.receiver_id] = true;
            });
            setFollowingStatus(status);
        };
        initFollowingStatus();
    }, [userPersonalInfo]);

    const handleFollow = async (userId) => {
        try {
            const data = { receiver_id: userId };
            await follow(data, token);
            setFollowingStatus(prevStatus => ({
                ...prevStatus,
                [userId]: true
            }));
            setUserPersonalInfo(prevInfo => ({
                ...prevInfo,
                user: {
                    ...prevInfo.user,
                    Following: [...prevInfo.user.Following, { UserRelationship: { receiver_id: userId } }]
                }
            }));

        } catch (error) {
            console.error(`Error following user ${userId}:`, error);
        }
    };

    const handleUnfollow = async (userId) => {
        try {
            const data = { receiver_id: userId };
            await unfollow(data, token);
            setFollowingStatus(prevStatus => ({
                ...prevStatus,
                [userId]: false
            }));
            setUserPersonalInfo(prevInfo => ({
                ...prevInfo,
                user: {
                    ...prevInfo.user,
                    Following: prevInfo.user.Following.filter(follower => follower.UserRelationship.receiver_id !== userId)
                }
            }));
            // setFollowersDetails(prevDetails => prevDetails.filter(detail => detail.user.id !== userId));

        } catch (error) {
            console.error(`Error unfollowing user ${userId}:`, error);
        }
    };

    useEffect(() => {
        const initFollowingStatus = () => {
            const status = {};
            userPersonalInfo?.user?.Following?.forEach(follower => {
                status[follower.UserRelationship.receiver_id] = true;
            });
            setFollowingStatus(status);
        };
        initFollowingStatus();
    }, [userPersonalInfo]);

    const isFollowing = (userId) => {
        return followingStatus[userId];
    };

    const handleFollowButtonPress = (userId) => {
        if (isFollowing(userId)) {
            handleUnfollow(userId).catch(error => console.error(`Error unfollowing user ${userId}:`, error));
        } else {
            handleFollow(userId).catch(error => console.error(`Error following user ${userId}:`, error));
        }
        fetchUserDetails();
    };

    //############### FOLLOW/FOLLOWING DATA END ######################//

    //############### HANDLE PROFILE PIC UPDATE ######################//
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {

        const formData = new FormData();
        formData.append('images', selectedFile);

        try {
            const config = {
                headers: {
                    Authorization: token,
                    'Content-Type': 'multipart/form-data'
                }
            };
            const response = await axios.post('https://api.dreamlived.com/users/changeProfilePicture', formData, config);
            if (response.data.message === 'success') {
                fetchUserDetails(); // Refetch user details to get the updated profile picture
                alert('Profile picture uploaded successfully!');
                setSelectedFile(null);
            } else {
                setSelectedFile(null);
                alert('Failed to upload profile picture. Please try again. 1');
            }
        } catch (error) {
            console.error('Error uploading profile picture:', error);
            setSelectedFile(null);
            alert('Failed to upload profile picture. Please try again.2');

        }
    };
    //############### HANDLE PROFILE PIC UPDATE END ######################//





    return (
        <div className='profile-layout-bg-container'>
            <div className='profile-layout-main-container'>

                {/* sidebar container */}
                <div className='profile-layout-sidebar'>
                    <Sidebar />
                </div>
                {/* sidebar container */}

                {/* middle container */}
                <div className='profile-layout-middle-container'>
                    <div className='profile-home-bg-main-container'>
                        {/* <Header /> */}

                        <div className="profile-video-main-container">
                            <div className="profile-page">
                                <div className="profile-header">
                                    <div className="profile-avatar">
                                        <img src={userPersonalNewInfo?.user?.profile_pic} alt="user avatar" className="profile-avatar" />

                                    </div>
                                    <div className="avatar-initial-details">
                                        <div className="avatar-initial-username">{userPersonalNewInfo?.user?.nickname}</div>
                                        <div className="avatar-initial-username-id">{userPersonalNewInfo?.user?.username}</div>
                                        <button className="edit-profile-btn" onClick={profileOpen}>Edit profile</button>
                                        <Modal isOpen={profileActive} onRequestClose={profileClose} style={customStyles}>
                                            <div>
                                                <div className="fixed-header">

                                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                                                        <img src={userPersonalNewInfo?.user?.profile_pic} alt="user avatar" className="edit-profile-avatar" />
                                                        <label htmlFor="profilePicInput" style={{ cursor: "pointer" }}>
                                                            <span style={{ fontWeight: "500" }}>Change Photo</span>
                                                        </label>
                                                        <input type="file" accept="image/*,.gif" id="profilePicInput" onChange={handleFileChange} style={{ display: "none" }} />

                                                        {selectedFile && (
                                                            <button onClick={handleUpload} className="upload-button">
                                                                Upload
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="profile-scrollable-content">
                                                    <div className="portfolio-details">
                                                        {renderDetailItem('Nickname', 'nickname')}
                                                        {renderDetailItem('Username', 'username')}
                                                        {renderDetailItem('Gender', 'gender')}
                                                        {renderDetailItem('Birthday', 'dob', 'date')}
                                                        {renderDetailItem('Bio', 'bio')}
                                                        {renderDetailItem('Country', 'country')}
                                                        {renderDetailItem('Emotion State', 'emotionState')}
                                                        {renderDetailItem('Making Friend Intention', 'friendIntention')}
                                                        {renderDetailItem('Occupation', 'occupation')}
                                                        {renderDetailItem('Mastery of language', 'languageMastery')}
                                                        {renderDetailItem('Hobbies', 'hobbies')}
                                                        {renderDetailItem('Height', 'person_height')}
                                                        {renderDetailItem('Weight', 'person_weight')}
                                                        {renderDetailItem('Instagram', 'instagram')}
                                                        {renderDetailItem('Youtube', 'you_tube')}
                                                        {renderDetailItem('Facebook', 'facebook')}
                                                        {renderDetailItem('Twitter', 'twitter')}
                                                    </div>
                                                </div>

                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                                <div className="profile-stats">
                                    <div className="stat"><span>{userPersonalInfo?.user?.Following.length}</span> <span onClick={() => { handleOpen(); handleTabChange('Following'); }}>Following</span></div>
                                    <div className="stat"><span>{userPersonalInfo?.user?.Followers.length}</span> <span onClick={() => { handleOpen(); handleTabChange('Followers'); }} >Followers</span></div>
                                    <Modal isOpen={active} onRequestClose={handleClose} style={customStyles}>
                                        <div>
                                            {/* Fixed Header */}
                                            <div className="profile-fixed-header">
                                                <div style={{ fontSize: "25px", fontStyle: "bold", display: "flex", justifyContent: "space-between" }}>
                                                    {userPersonalInfo?.user?.nickname} <IoClose onClick={handleClose} />
                                                </div>
                                                <div className="profile-stats">
                                                    <div className="stat">
                                                        <span>{formatNumber(userPersonalInfo?.user?.Following.length)}</span>
                                                        <span
                                                            onClick={() => handleTabChange('Following')}
                                                            style={{
                                                                textDecoration: currentTab === 'Following' ? 'underline' : 'none',
                                                                cursor: 'pointer',
                                                                marginLeft: "5px",

                                                            }}
                                                        >
                                                            Following
                                                        </span>
                                                    </div>
                                                    <div className="stat">
                                                        <span>{formatNumber(userPersonalInfo?.user?.Followers.length)}</span>
                                                        <span
                                                            style={{
                                                                textDecoration: currentTab === 'Followers' ? 'underline' : 'none',
                                                                cursor: 'pointer',
                                                                marginLeft: "5px",
                                                            }}
                                                            onClick={() => handleTabChange('Followers')}
                                                        >
                                                            Followers
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Scrollable Content */}
                                            <div className="profile-scrollable-content">
                                                {currentTab === 'Followers' && userPersonalInfo?.user?.Followers?.map((user, id) => {
                                                    const followerDetail = followersDetails.find(detail => detail.user.id === user.id);
                                                    return (
                                                        <div key={id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", borderBottom: '1px solid #ccc', padding: '10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={followerDetail?.user?.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                                                                    alt={followerDetail?.user?.username}
                                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                                />
                                                                <div>
                                                                    <div style={{ marginLeft: '15px', fontWeight: "bold" }}> {followerDetail?.user?.nickname}</div>
                                                                    <div style={{ marginLeft: '15px', color: "grey" }}>{user.username}</div>
                                                                </div>
                                                            </div>
                                                            <button
                                                                onClick={() => handleFollowButtonPress(user.UserRelationship.sender_id)}
                                                                style={{ backgroundColor: isFollowing(user.UserRelationship.sender_id) ? '#FF6347' : '#3897f0', color: '#fff', border: 'none', borderRadius: '4px', padding: '3px 10px', height: "30px", cursor: "pointer" }}>
                                                                {isFollowing(user.UserRelationship.sender_id) ? 'Friends' : 'Follow Back'}
                                                            </button>
                                                        </div>
                                                    );
                                                })}
                                                {currentTab === 'Following' && userPersonalInfo?.user?.Following?.map((user, id) => {
                                                    const followerDetail = followersDetails.find(detail => detail.user.id === user.id);
                                                    return (
                                                        <div key={id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", borderBottom: '1px solid #ccc', padding: '10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={followerDetail?.user?.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                                                                    alt={followerDetail?.user?.username}
                                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                                />

                                                                <div>
                                                                    <div style={{ marginLeft: '15px', fontWeight: "bold" }}> {followerDetail?.user?.nickname}</div>
                                                                    <div style={{ marginLeft: '15px', color: "grey" }}>{user.username}</div>
                                                                </div>
                                                            </div>
                                                            <button
                                                                onClick={() => handleFollowButtonPress(user.UserRelationship.receiver_id)}
                                                                style={{ backgroundColor: isFollowing(user.UserRelationship.receiver_id) ? '#FF6347' : '#3897f0', color: '#fff', border: 'none', borderRadius: '4px', padding: '3px 10px', height: "30px", cursor: "pointer" }}>
                                                                {isFollowing(user.UserRelationship.receiver_id) ? 'Following' : 'Follow'}
                                                            </button>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </Modal>

                                    <div className="stat" onClick={() => likeModalOpen()}><span>{userPersonalNewInfo?.liked_video?.length}</span> <span style={{ textDecoration: "none" }}>Likes</span></div>

                                    <Modal isOpen={likeModalActive} onRequestClose={likeModalClose} style={customStyles}>
                                        <div className="profile-fixed-header" style={{ fontSize: "25px", fontStyle: "bold", display: "flex", justifyContent: "space-between" }}>
                                            Liked Videos Users
                                        </div>
                                        <div className="profile-scrollable-content">
                                            {Array.from(new Set(userPersonalInfo?.liked_video?.map(user => user.video.user.id)))
                                                .map((uniqueId, index) => {
                                                    const uniqueUser = userPersonalInfo?.liked_video.find(user => user.video.user.id === uniqueId);
                                                    return (
                                                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", borderBottom: '1px solid #ccc', padding: '10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={uniqueUser?.video?.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                                                                    alt={uniqueUser?.video?.profile_pic}
                                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                                />
                                                                <div>
                                                                    <div style={{ marginLeft: '15px', fontWeight: "bold" }}> {uniqueUser?.video?.user?.nickname}</div>
                                                                    <div style={{ marginLeft: '15px', color: "grey" }}>{uniqueUser?.video?.user?.username}</div>
                                                                </div>
                                                            </div>
                                                            <button
                                                                onClick={() => handleFollowButtonPress(uniqueUser.video.user.id)}
                                                                style={{ backgroundColor: isFollowing(uniqueUser.video.user.id) ? '#FF6347' : '#3897f0', color: '#fff', border: 'none', borderRadius: '4px', padding: '3px 10px', height: "30px", cursor: "pointer" }}>
                                                                {isFollowing(uniqueUser.video.user.id) ? 'Following' : 'Follow'}
                                                            </button>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </Modal>
                                </div>
                                <div className="bio">{userPersonalNewInfo?.user?.bio}</div>

                            </div>
                            <div className="content-category-buttons">
                                <button className={`tab ${currentVideoTab === 'Like' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Like')}><FaRegHeart style={{}} />
                                    Like</button>
                                <button className={`tab ${currentVideoTab === 'Favorite' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Favorite')}><FaRegBookmark style={{}} />
                                    Favorite</button>
                                <button className={`tab ${currentVideoTab === 'Picture' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Picture')}><ImLoop style={{}} />
                                    Picture</button>
                                <button className={`tab ${currentVideoTab === 'Private' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Private')}><MdLockOutline style={{ fontWeight: "700", fontSize: "23px" }} />
                                    Private</button>
                                <button className={`tab ${currentVideoTab === 'Videos' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Videos')}><GiHamburgerMenu style={{}} />
                                    Videos</button>
                            </div>
                        </div>


                        <div className="video-sections">

                            {currentVideoTab === 'Videos' && (
                                <div className="video-cards">
                                    <Grid
                                        columnCount={4} // Number of columns in the grid
                                        columnWidth={270} // Width of each column
                                        height={800} // Height of the grid
                                        rowCount={Math.ceil(userPersonalNewInfo?.user?.videos?.length / 4)} // Number of rows
                                        rowHeight={365} // Height of each row
                                        width={1080} // Fixed width for the grid (must be a number)
                                    >
                                        {({ columnIndex, rowIndex, style }) => {
                                            const videoIndex = rowIndex * 4 + columnIndex;
                                            const video = userPersonalNewInfo?.user?.videos[videoIndex];
                                            //userPersonalInfo?.user?.videos
                                            // console.log("privateVideo,", { video })
                                            return <Row columnIndex={columnIndex} rowIndex={rowIndex} style={style} video={video} />;
                                        }}
                                    </Grid>
                                </div>
                            )}

                            {currentVideoTab === 'Favorite' && (
                                <div>
                                    <div className="profile-liked-btn-section">
                                        <button className={`profile-liked-btn-style ${activeFavoriteSubTab === 'FavVideos' ? 'active' : ''}`} onClick={() => handleFavoriteSubTabClick('FavVideos')}>Videos <span style={{ fontWeight: "bold" }}>
                                            {/* {userPersonalInfo?.fav_video.length} */}
                                        </span></button>
                                        <button className={`profile-liked-btn-style ${activeFavoriteSubTab === 'Sounds' ? 'active' : ''}`} onClick={() => handleFavoriteSubTabClick('Sounds')}>Sounds <span style={{ fontWeight: "bold" }}>
                                            {/* 2 */}
                                        </span></button>
                                        <button className={`profile-liked-btn-style ${activeFavoriteSubTab === 'Hashtags' ? 'active' : ''}`} onClick={() => handleFavoriteSubTabClick('Hashtags')}>Hashtags <span style={{ fontWeight: "bold" }}>
                                            {/* {hashtagData.length} */}
                                            {/* 2 */}
                                        </span></button>
                                        <button className={`profile-liked-btn-style ${activeFavoriteSubTab === 'Users' ? 'active' : ''}`} onClick={() => handleFavoriteSubTabClick('Users')}>Users <span style={{ fontWeight: "bold" }}>
                                            {/* usersData */}
                                        </span></button>
                                    </div>
                                    {activeFavoriteSubTab === "FavVideos" && (
                                        <VideoList videoData={userPersonalNewInfo?.fav_video} />
                                    )}
                                    {activeFavoriteSubTab === "Sounds" && (
                                        <SongList />
                                    )}
                                    {activeFavoriteSubTab === "Hashtags" && (
                                        <HashtagList />
                                    )}
                                    {activeFavoriteSubTab === "Users" && (
                                        <UserList />
                                    )}
                                </div>
                            )}

                            {currentVideoTab === 'Picture' && (
                                <div className="video-cards">
                                    {userPersonalNewInfo?.user?.videos?.map((video) => (
                                        <div key={video.id} className="video-card">
                                            <img src={video.profile_pic} alt={video.description} className="video-thumbnail" />
                                        </div>
                                    ))}
                                </div>
                            )}



                            {currentVideoTab === 'Private' && (
                                <div className="video-cards">
                                    {/* Replace with favorite videos data */}
                                    <Grid
                                        columnCount={4} // Number of columns in the grid
                                        columnWidth={270} // Width of each column
                                        height={800} // Height of the grid
                                        rowCount={Math.ceil(userPersonalNewInfo?.private_user?.videos?.length / 4)} // Number of rows
                                        rowHeight={365} // Height of each row
                                        width={1080} // Fixed width for the grid (must be a number)
                                    >
                                        {({ columnIndex, rowIndex, style }) => {
                                            const videoIndex = rowIndex * 4 + columnIndex;
                                            const video = userPersonalNewInfo?.private_user?.videos[videoIndex];
                                            // console.log("privateVideo,", { video })
                                            return <Row columnIndex={columnIndex} rowIndex={rowIndex} style={style} video={video} />;
                                        }}
                                    </Grid>
                                </div>

                            )}

                            {currentVideoTab === 'Like' && userPersonalNewInfo?.liked_video && (
                                <div className="video-cards">
                                    <Grid
                                        columnCount={4} // Number of columns in the grid
                                        columnWidth={270} // Width of each column
                                        height={800} // Height of the grid
                                        rowCount={Math.ceil(userPersonalNewInfo?.liked_video?.length / 4)} // Number of rows
                                        rowHeight={365} // Height of each row
                                        width={1080} // Fixed width for the grid (must be a number)
                                    >
                                        {({ columnIndex, rowIndex, style }) => {
                                            const videoIndex = rowIndex * 4 + columnIndex;
                                            const video = userPersonalNewInfo?.liked_video[videoIndex]?.video;
                                            return <Row columnIndex={columnIndex} rowIndex={rowIndex} style={style} video={video} />;
                                        }}
                                    </Grid>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
                {/* middle container */}

            </div >
        </div >
    );
}

export default Profile;

