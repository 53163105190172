const userData = {
    "account_type": "premium",
    "active": 1,
    "auth_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNrQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoic2h1YmhhbXMiLCJpYXQiOjE3MTk1ODI0MTd9.oYdTRGvMvwCub8aqDPyN3KluPjMqEYfOcay3fvAc3sY",
    "bio": "full stack engineers",
    "city": null,
    "city_id": null,
    "country": "Armenia",
    "country_id": null,
    "creationDate": "2023-07-21",
    "device": null,
    "device_token": "eSUag3EgTu-aR2y2062ACm:APA91bF58cpytPVwk1upt9oNdc5O3v6uWfE8dsnXzbAaeGULEXtiPaxgfPCJtrPo_lkNDAyOSOAFMPUqtbKdn17hWAon6IG7yz9mzeGuUCS4exNBEzBTqIZxPdQe7P5j4CDAGPqx21pD",
    "dob": "2028-06-19T18:26:00.000Z",
    "email": "sk@gmail.com",
    "emotion_state": "In Love",
    "facebook": null,
    "fb_id": null,
    "firebase_uid": "nGrabWx2opdSiywYlZeRcxujIsD3",
    "gender": "Other",
    "hobbies": [
        "Fishing"
    ],
    "id": 11,
    "instagram": null,
    "ip": "192.168.1.14",
    "lang": null,
    "language": [
        "Arabic"
    ],
    "lat": null,
    "making_friend_intention": [
        "18-26",
        "Local",
        "Hot chat"
    ],
    "nickname": "shubham",
    "occupation": "Cricket",
    "online": null,
    "password": null,
    "paypal": null,
    "person_height": null,
    "person_weight": null,
    "phone": null,
    "profile_pic": "https://dpcst9y3un003.cloudfront.net/avatar/image7.jpg",
    "profile_pic_small": null,
    "profile_video": "https://dpcst9y3un003.cloudfront.net/images/1000000033",
    "reset_wallet_datetime": null,
    "role": "user",
    "social": null,
    "social_id": null,
    "state_id": null,
    "token": null,
    "twitter": null,
    "username": "shubhams",
    "verified": 0,
    "version": null,
    "wallet": 9362,
    "website": null,
    "you_tube": null
};

const formatNumber = (number) => {
    if (number == null) return '0'; // Handle null or undefined
    if (number >= 1000000) {
        return (number / 1000000).toFixed(1) + 'M';
    } else if (number >= 1000) {
        return (number / 1000).toFixed(1) + 'K';
    }
    return number.toString();
};

// Correct default export without curly braces
export { userData, formatNumber };
