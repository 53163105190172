import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import './buttonElm.css'; // Assuming you have a CSS file named Button.css
import { postVideo } from '../../apis/userApi'; // Make sure postVideo handles the form data correctly

//Assests
import { AiOutlineCloudUpload } from "react-icons/ai";

//REDUX
import { useDispatch, useSelector } from 'react-redux';

// Import the thumbnail generator library
import VideoThumbnail from 'react-video-thumbnail';
import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating unique identifiers

// Set the app element for accessibility
Modal.setAppElement('#root'); // Make sure '#root' matches your app's root element ID

const customStyles = {
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%', // Adjust width as needed
        maxWidth: '600px',
        zIndex: 1000,
        backgroundColor: "white",
        height: "80%",
        borderRadius: "20px",
        padding: "30px",
        gap: '15px', // Spacing between elements
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
        // transition: "opacity 2000ms ease-in-out",
        position: "fixed",
        zIndex: 900, // Ensure it appears below the content
        top: 0,
        left: 0,
        right: 0,
        bottom: 0

    }
};

// Add some CSS styles for better form element layout
const inputStyles = {
    width: '100%',
    padding: '10px',
    margin: '5px 0',
    borderRadius: '5px',
    border: '1px solid #ccc'
};

const textareaStyles = {
    ...inputStyles,
    height: '100px',
    resize: 'none'
};

const selectStyles = {
    ...inputStyles
};

const labelStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '14px'
};

const buttonStyles = {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer'
};

const cancelButtonStyles = {
    ...buttonStyles,
    backgroundColor: '#f44336',
    color: '#fff'
};

const submitButtonStyles = {
    ...buttonStyles,
    backgroundColor: '#4CAF50',
    color: '#fff'
};

function ButtonElm() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [videoFile, setVideoFile] = useState(null);
    const [title, setTitle] = useState('');
    const [caption, setCaption] = useState('');
    const [privacy, setPrivacy] = useState('public');
    const [allowComment, setAllowComment] = useState(true);
    const [allowDuet, setAllowDuet] = useState(true);
    const [allowStitch, setAllowStitch] = useState(true);
    const [countries, setCountries] = useState('');
    const [cities, setCities] = useState('');
    const [hashtag, setHashtag] = useState('');
    const [tagPeople, setTagPeople] = useState('');
    const [taggedPeopleId, setTaggedPeopleId] = useState('');
    const [videoTopic, setVideoTopic] = useState('');
    const [videoDurations, setVideoDurations] = useState('');
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);

    //GENERATE THUMBNAIL
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [image, setImage] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    //REDUX
    const dispatch = useDispatch();
    const video = useSelector(state => state.video)

    // Helper function to convert a blob to JPEG if necessary
    const convertToJPEG = async (blob) => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const img = new Image();

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                canvas.toBlob((jpegBlob) => {
                    resolve(jpegBlob);
                }, 'image/jpeg');
            };

            img.onerror = reject;
            img.src = URL.createObjectURL(blob);
        });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setVideoFile(file);
        // setThumbnailUrl(null); // Reset thumbnail URL when a new file is selected
        if (!videoFile) {
            setThumbnailUrl(null); // Reset thumbnail URL if no video file is selected
        }

        if (file) {
            const videoElement = document.createElement('video');
            videoElement.preload = 'metadata';

            videoElement.onloadedmetadata = () => {
                window.URL.revokeObjectURL(videoElement.src); // Clean up the object URL
                const duration = videoElement.duration;
                setVideoDurations(duration); // Set the duration
            };

            videoElement.src = URL.createObjectURL(file); // Load the file to extract duration
        }
    };

    // useEffect(() => {

    // }, [videoFile]);


    // Function to generate thumbnail
    // const generateThumbnailImage = async (videoFile) => {
    //     if (videoFile) {
    //         try {
    //             const videoUrl = URL.createObjectURL(videoFile);

    //             // Generate thumbnail at 1 second
    //             const thumbnail = await generateThumbnail(videoUrl, { seekTime: 1 });

    //             setImage(thumbnail);
    //         } catch (error) {
    //             console.error('Error generating thumbnail:', error);
    //             setError('Failed to generate thumbnail. Please try again.');
    //         }
    //     }
    // };

    // useEffect(() => {
    //     if (videoFile) {
    //         generateThumbnailImage(videoFile);
    //     }
    // }, [videoFile]);



    // const handlePostVideo = async () => {
    //     if (videoFile) {
    //         try {
    //             if (title.length > 0 && caption.length > 0) {
    //                 setUploading(true);
    //                 const formData = new FormData();
    //                 // formData.append('video', {
    //                 //     uri: `${videoFile?.video_url}`,
    //                 //     name: videoFile?.video_url.split('/').reverse()[0],
    //                 //     type: 'video/mp4',
    //                 // });
    //                 formData.append('video', {
    //                     uri: URL.createObjectURL(videoFile),
    //                     name: videoFile.name,
    //                     type: videoFile.type,
    //                 });
    //                 formData.append('cover', {
    //                     uri: image,
    //                     name: image?.split('/').reverse()[0],
    //                     type: 'image/jpeg'
    //                 })
    //                 // formData.append('video', videoFile);
    //                 // formData.append('caption', caption);
    //                 formData.append('privacy', privacy);
    //                 formData.append('allow_comment', allowComment);
    //                 formData.append('allow_duet', allowDuet);
    //                 formData.append('allow_stitch', allowStitch);
    //                 formData.append('countries', countries);
    //                 formData.append('cities', cities);
    //                 formData.append('hashtag', hashtag);
    //                 formData.append('tag_people', tagPeople);
    //                 formData.append('tagged_people_id', taggedPeopleId);
    //                 formData.append('title', title);
    //                 formData.append('video_topic', videoTopic);
    //                 formData.append('video_durations', videoDurations);

    //                 const response = await postVideo(formData);

    //                 console.log('Video uploaded successfully:', response.data);
    //                 setModalIsOpen(false);
    //             } else {
    //                 alert("Minimum 50 characters are allowed in the description...");
    //             }
    //         } catch (error) {
    //             console.error('Error uploading video:', error);
    //             setError('Failed to upload video. Please try again.');
    //         } finally {
    //             setUploading(false);
    //         }
    //     } else {
    //         alert("Please select a video file.");
    //     }
    // };

    // console.log("thumbnailUrl", thumbnailUrl);
    console.log("videofile", videoFile);

    const handlePostVideo = async () => {
        if (videoFile) {
            try {
                if (title.length > 10 && caption.length > 20) {
                    setUploading(true); // Set uploading state to true
                    const formData = new FormData();

                    // Generate dynamic filenames
                    const videoFilename = `videos/${Date.now()}.mp4`;
                    const thumbnailFilename = `thumb-${uuidv4()}`;
                    setVideoTopic(videoFilename);
                    setCities("city_name");
                    setTaggedPeopleId("NA");
                    // Append video file to formData
                    formData.append('video', videoFile, videoFilename);
                    // Append cover image if available
                    //VERSION 1
                    // let image1 = "images/thumb-9d68e369-7dc0-4480-8e9a-33f9a761f525"
                    // const blob = new Blob([], { type: 'image/jpeg' }); // Empty Blob for simulation
                    // formData.append('cover', blob, image1?.split('/').reverse()[0]);

                    //VERSION2
                    // Generate and append thumbnail as cover image
                    if (thumbnailUrl) {
                        const response = await fetch(thumbnailUrl);
                        const blob = await response.blob();
                        // Convert the blob to JPEG if needed
                        const jpegBlob = await convertToJPEG(blob);
                        formData.append('cover', jpegBlob, thumbnailFilename);
                    }
                    console.log("videofile", videoFile);
                    // Append additional form data
                    formData.append('caption', caption);
                    formData.append('privacy', privacy);
                    formData.append('allow_comment', allowComment);
                    formData.append('allow_duet', allowDuet);
                    formData.append('allow_stitch', allowStitch);
                    formData.append('countries', countries);
                    formData.append('cities', cities);
                    formData.append('hashtag', hashtag);
                    formData.append('tag_people', tagPeople);
                    formData.append('tagged_people_id', taggedPeopleId);
                    formData.append('title', title);
                    formData.append('video_topic', videoTopic);
                    formData.append('video_durations', videoDurations);

                    console.log(Object.fromEntries(formData));
                    // Post video and handle response
                    const response = await postVideo(formData);

                    // console.log('Video uploaded successfully:', response.data);
                    alert('Video uploaded successfully!');

                    setModalIsOpen(false); // Close modal after successful upload
                } else {
                    alert("Minimum 20 & 40 characters required for title & description.");
                }
            } catch (error) {
                console.error('Error uploading video:', error);
                setError('Failed to upload video. Please try again.');
            } finally {
                setUploading(false); // Set uploading state to false regardless of outcome
                // setVideoFile(null);
            }
        } else {
            alert("Please select a video file.");
        }
    };



    return (
        <div>
            <button className="postButton" onClick={() => setModalIsOpen(true)} disabled={uploading} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                <AiOutlineCloudUpload />
                <div className='upload-text-style'>
                    {uploading ? 'Uploading...' : 'Upload Video'}
                </div>
            </button>
            {error && <div className="error-message">{error}</div>}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Video Upload Modal"
                style={customStyles}
            >
                <h2>Upload Video</h2>
                <input
                    onChange={handleFileChange}
                    type="file"
                    accept="video/*"
                    // onChange={(event) => setVideoFile(event.target.files[0])}
                    style={inputStyles}
                />
                <div >
                    {/* {console.log("thumbnailUrl", thumbnailUrl)} */}
                    {/* VERSION 1 */}
                    {videoFile && (
                        <VideoThumbnail
                            videoUrl={URL.createObjectURL(videoFile)}
                            thumbnailHandler={(thumbnail) => setThumbnailUrl(thumbnail)}
                            width={720}
                            height={1280}
                            renderThumbnail={false}
                        // snapshotAtTime={10}
                        />
                    )}
                </div>


                <input
                    type="text"
                    placeholder="Short Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={inputStyles}

                />
                <textarea
                    placeholder="Description"
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                    style={textareaStyles}
                />
                <select value={privacy} onChange={(e) => setPrivacy(e.target.value)} style={selectStyles}>
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                </select>
                <label style={labelStyles}>
                    Allow Comments
                    <input
                        type="checkbox"
                        checked={allowComment}
                        onChange={(e) => setAllowComment(e.target.checked)}
                    />
                </label>
                <label style={labelStyles}>
                    Allow Duet
                    <input
                        type="checkbox"
                        checked={allowDuet}
                        onChange={(e) => setAllowDuet(e.target.checked)}
                    />
                </label>
                <label style={labelStyles}>
                    Allow Stitch
                    <input
                        type="checkbox"
                        checked={allowStitch}
                        onChange={(e) => setAllowStitch(e.target.checked)}
                    />
                </label>
                <select onChange={(e) => setCountries(e.target.value)} style={selectStyles}>
                    <option value="kuwait">Kuwait</option>
                    <option value="arabian gulf">Arabian Gulf</option>
                    <option value="turkey">Turkey</option>
                    <option value="europe">Europe</option>
                    <option value="americas">Americas</option>
                    <option value="canada">Canada</option>
                </select>
                {/* <input
                    type="text"
                    placeholder="Cities"
                    value={cities}
                    onChange={(e) => setCities(e.target.value)}
                    style={inputStyles}
                /> */}
                <input
                    type="text"
                    placeholder="Hashtag"
                    value={hashtag}
                    onChange={(e) => setHashtag(e.target.value)}
                    style={inputStyles}
                />
                <input
                    type="text"
                    placeholder="Tag People"
                    value={tagPeople}
                    onChange={(e) => setTagPeople(e.target.value)}
                    style={inputStyles}
                />
                {/* <input
                    type="text"
                    placeholder="Tagged People ID"
                    value={taggedPeopleId}
                    onChange={(e) => setTaggedPeopleId(e.target.value)}
                    style={inputStyles}
                /> */}
                {/* <input
                    type="text"
                    placeholder="Video Topic"
                    value={videoTopic}
                    onChange={(e) => setVideoTopic(e.target.value)}
                    style={inputStyles}
                /> */}
                {/* <input
                    type="text"
                    placeholder="Video Durations"
                    value={videoDurations}
                    onChange={(e) => setVideoDurations(e.target.value)}
                    style={inputStyles}
                /> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <button onClick={handlePostVideo} style={submitButtonStyles} disabled={uploading}>
                        {uploading ? 'Uploading...' : 'Post Video'}
                    </button>
                    <button onClick={() => setModalIsOpen(false)} style={cancelButtonStyles}>
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default ButtonElm;
