import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {FaHeart} from 'react-icons/fa';
import { PiDotsThreeOutlineThin } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";

import '../rightSidebar/rightSidebar.js';

const Comment = ({ comment, videoId }) => {
    const history = useNavigate();
    const [showReplies, setShowReplies] = useState(false);
    const [replyText, setReplyText] = useState('');
    // const [replyLike, setReplyLike] = useState(false);
    const [manageComments, setManageComments] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [isLike, setIsLike] = useState(false);
    const [num_like, setNum_like] = useState(0);
    const [increaseCount, setIncreaseCount] = useState(1);

    useEffect(() => {
        const isIdPresent = comment.comment_likes.some(item => item?.sender_id === userDetails?.id);
        setIsLike(isIdPresent);
        setNum_like(comment.comment_likes?.length);
    }, [userDetails]);

    useEffect(() => {
        const storedUserDetails = localStorage.getItem('userDetails');
        if (storedUserDetails) {
            try {
                setUserDetails(JSON.parse(storedUserDetails));
            } catch (error) {
                console.error("Error parsing user details from localStorage:", error);
            }
        } else {
            setUserDetails(null); // Ensure userDetails is set to null if nothing is found
        }
    }, []);

    const handleReplySubmit = async (e) => {
        e.preventDefault();

        if (!userDetails) {
            console.error('User details not found.');
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: videoId,
            parent_comment_id: comment.id,
            reply_message: replyText,
        };

        try {
            const url = `https://api.dreamlived.com/comments/replyComment`;
            const result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });
            // console.log("usercomments", result);
            setReplyText('');
            // fetchComments(videoId); // Ensure comments are re-fetched after creating a new comment
            setIncreaseCount(increaseCount + 1);
        } catch (error) {
            console.error("Error updating like status:", error);
        }
    };

    const onCreateCommentsLike = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            history('/auth', {replace:true})
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        setIsLike(!isLike);
        setNum_like(isLike ? num_like - 1 : num_like + 1);

        try {
            const url = `https://api.dreamlived.com/comments/likeComment`;
            const result = await axios.post(url, {
                video_id: videoId,
                comment_id: comment.id,
            }, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });
            // console.log("likecomments", result);
            // fetchComments(videoId);
        } catch (error) {
            console.error("Error updating like status:", error);
        }
    };

    return (
        <>
            {manageComments && (
                <div className='manage-comment-popup'>
                    <RxCross2 className='close-icon' onClick={() => setManageComments(false)} />
                    <button type='button' className='edit-button'>Edit</button>
                    <button type='button' className='delete-button'>Delete</button>
                </div>
            )}

            <div className="comment">
                <img src={comment.user.profile_pic} alt={`${comment.user.username}'s profile`} className="comment-avatar" />
                <div className="comment-content">
                    <div className="comment-header">
                        <span className="comment-username">{comment.user.username}</span>
                        <span className="comment-time">1d ago</span>
                    </div>
                    <div className="comment-text">{comment.comment_data}</div>
                    <div className="comment-footer">
                        <span className="comment-reply" onClick={() => setShowReplies(!showReplies)}>Reply</span>
                        <FaHeart className="comment-like" style={{ color: isLike ? 'red' : '#CBBDBD' }} onClick={onCreateCommentsLike} />
                        <span className="comment-likes">{num_like}</span>
                        <span className="view-replies" onClick={() => setShowReplies(!showReplies)}>
                            View {comment.replies.length} replies
                        </span>
                        <PiDotsThreeOutlineThin onClick={() => setManageComments(true)} />
                    </div>
                    {showReplies && comment.replies.length >= 0 && (
                        <div className="replies">
                            {comment.replies.slice(0, increaseCount).map((reply) => (
                                <div key={reply.id} className="reply">
                                    <img src={reply.user.profile_pic} alt="user" className="reply-avatar" style={{ width: "30px", height: "30px" }} />
                                    <div className='comments-reply-bottom'>
                                        <span className="comment-username" style={{ marginBottom: "5px" }}>{reply.user.username}</span>
                                        <div className="comment-text">{reply.reply_message}</div>
                                        <span className="replies-like"></span>
                                    </div>
                                </div>
                            ))}
                            <button className="reply-next" onClick={() => setIncreaseCount(increaseCount + 1)}>next</button>
                            <form onSubmit={handleReplySubmit} className="reply-forms">
                                <div className="reply-form">
                                    <input
                                        type="text"
                                        value={replyText}
                                        onChange={(e) => setReplyText(e.target.value)}
                                        placeholder="Write a reply..."
                                        className="reply-input"
                                    />
                                </div>
                                <button type="submit" className="reply-button">Reply</button>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Comment