import React from 'react';
import './userProfile.css';

const UserProfile = () => {
    return (
        <div className="userProfile">
            <Avatar />
            <Username />
            <Options />
        </div>
    );
};

const Avatar = () => {
    return (
        <img src="https://assets.monica.im/tools-web/_next/static/media/imageGeneratorFeatureIntro1.9f5e7e23.webp" alt="user avatar" className="userProfile-avatar" />
    );
};

const Username = () => {
    return (
        <div className="userProfile-username">
            <div>xd8c</div>
            <div >@itsxd8c</div>
        </div>
    );
};

const Options = () => {
    return (
        <div className="options">...</div>
    );
};

export default UserProfile;
