import "./userProfile.css";
import React, { useEffect, useState } from "react";
// import { useParams } from 'react-router-dom';

import Sidebar from "../../components/sidebar/sidebar";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { FaAngleRight, FaRegBookmark } from "react-icons/fa6";
import { ImLoop } from "react-icons/im";
import { MdLockOutline } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaPlay } from "react-icons/fa";
import DIAMOND_ICON from "../../assets/images/diamond.png";
import { formatNumber } from "../../config/userData";
// import { fetchUserInfoById } from "../../redux/userSlice";
import { useParams } from "react-router-dom";
import { FixedSizeGrid as Grid } from 'react-window';

//IMPORT REDUX ELEMENTS
import { useSelector, useDispatch } from "react-redux";
import { fetchUserResults } from "../../slices/userSlice";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const UserProfile = () => {
    //REACT ROUTER SETUP TO DISPATCH DATA
    const { userId } = useParams();

    //REDUX SETUP TO DISPATCH DATA
    const dispatch = useDispatch();
    const { userInfo, error, loading } = useSelector(state => state.user);
    useEffect(() => {
        dispatch(fetchUserResults(userId));
    }, [dispatch, userId]);
    console.log("userInfo", userInfo);
    const [profileActive, setProfileActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const profileOpen = () => setProfileActive(true);
    const profileClose = () => setProfileActive(false);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        // Handle the file upload logic here
    };

    const updateProfile = (key, value) => {
        // Implement the update profile logic here
    };

    const handleOpen = () => {
        // Handle open modal logic here
    };

    const handleClose = () => {
        // Handle close modal logic here
    };

    const handleTabChange = (tab) => {
        // Handle tab change logic here
    };

    const isFollowing = (userId) => {
        // Check if the user is following logic here
        return false; // Placeholder return value
    };

    const handleFollowButtonPress = (userId) => {
        // Handle follow button press logic here
    };

    const handleUnfollowButtonPress = (userId) => {
        // Handle unfollow button press logic here
    };

    const renderDetailItem = (label, key, type = 'text') => (
        <div className="portfolio-item">
            <span>{label}:</span>
            <span>
                {/* {type === 'date' ? (
                    <DatePicker selected={userInfo.user[key]} onChange={(date) => updateProfile(key, date)} />
                ) : ( */}
                {userInfo.user[key]}
                {/* )} */}
            </span>
        </div>
    );

    //############## SCROLLING/THUMBNAIL LOGIC VIDEO SECTION ######################//
    const [currentPlayingVideoIndex, setCurrentPlayingVideoIndex] = useState(null);
    const [disableHover, setDisableHover] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState(null);

    const Row = ({ columnIndex, rowIndex, style, video }) => {
        const videoIndex = rowIndex * 4 + columnIndex;

        if (!video) {
            return null;
        }

        // const handleThumbnailHover = () => {
        //     if (!disableHover) {
        //         const timeout = setTimeout(() => {
        //             setCurrentPlayingVideoIndex(videoIndex);
        //         }, 2000); // Adding a delay of 2000ms before playing the video on hover

        //         setHoverTimeout(timeout);  // Store the timeout ID so we can clear it if needed
        //     }
        // };

        const handleThumbnailHover = () => {
            // const hoverStartTime = new Date(); // Record the time when the hover starts
            // if (!disableHover) {
            //     const timeout = setTimeout(() => {
            //         const hoverDuration = new Date();
            //         if (hoverDuration - hoverStartTime <= 2000) {
            //             setCurrentPlayingVideoIndex(videoIndex); // Play the video only if the hover duration is 2000 ms or more
            //         }
            //     }, 2000);

            //     setHoverTimeout(timeout); // Store the timeout ID so it can be cleared if needed
            //     return () => clearTimeout(timeout); // Clear the timeout if the hover ends before 2000 ms
            // }

        };




        const handleThumbnailClick = () => {
            setCurrentPlayingVideoIndex(videoIndex); // Play the video only if the hover duration is 2000 ms or more

            // Pause the video by setting the current playing index to null
            // setCurrentPlayingVideoIndex(null);
            // setDisableHover(true);  // Disable hover temporarily
            // clearTimeout(hoverTimeout);  // Clear any pending hover action
            // setTimeout(() => setDisableHover(false), 500);  // Re-enable hover after a short delay
        };

        return (
            <div style={style} className="video-card">
                {currentPlayingVideoIndex === videoIndex ? (
                    <video
                        width="100%"
                        height="360px"
                        controls
                        style={{ objectFit: "contain", backgroundColor: "black" }}
                        autoPlay
                        onPause={handleThumbnailClick}
                    >
                        <source src={`https://dpcst9y3un003.cloudfront.net/${video.video}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <>
                        <img
                            src={`https://dpcst9y3un003.cloudfront.net/${video.thum}`}
                            alt="Thumbnail"
                            width="100%"
                            height="360px"
                            style={{ objectFit: "cover", cursor: "pointer" }}
                            // onMouseEnter={handleThumbnailHover}

                            onClick={handleThumbnailClick}
                        />
                        <div style={{ fontWeight: "bold", position: 'absolute', bottom: 0, color: 'white', padding: '0px 10px', borderRadius: '5px', display: "flex", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
                            <p><FaPlay style={{ color: "white" }} /> {formatNumber(video.view)}</p>
                            <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img src={DIAMOND_ICON} alt="DiamondIcon" style={{ width: "40px", height: "40px" }}></img>
                                {formatNumber(video.diamond_value)}
                            </p>
                        </div>
                    </>
                )}
            </div>
        );
    };

    //##############  SCROLLING/THUMBNAIL LOGIC VIDEO SECTION ######################//

    //Handling Video/Likes/Favorites tab chnages.
    const [currentVideoTab, setCurrentVideoTab] = useState('Like'); // State for managing video sections
    const handleVideoTabChange = (tab) => {
        setCurrentVideoTab(tab);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading user data</div>;

    return (
        <div className='profile-layout-bg-container'>
            <div className='profile-layout-main-container'>
                <div className='profile-layout-sidebar'>
                    <Sidebar />
                </div>
                <div className='profile-layout-middle-container'>
                    <div className='profile-home-bg-main-container'>
                        <div className="profile-video-main-container">
                            <div className="profile-page">
                                <div className="profile-header">
                                    <div className="profile-avatar">
                                        <img src={userInfo?.user?.profile_pic || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="user avatar" className="profile-avatar" />
                                        {console.log(userInfo?.user?.profile_pic)}
                                    </div>
                                    <div className="avatar-initial-details">
                                        <div className="avatar-initial-username">{userInfo?.user?.nickname}</div>
                                        <div className="avatar-initial-username-id">{userInfo?.user?.username}</div>
                                        {/* <button className="edit-profile-btn" onClick={profileOpen}>Edit profile</button> */}
                                        {/* <Modal isOpen={profileActive} onRequestClose={profileClose} style={customStyles}>
                                            <div>
                                                <div className="fixed-header">
                                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                                                        <img src={userInfo?.user?.profile_pic} alt="user avatar" className="edit-profile-avatar" />
                                                        <label htmlFor="profilePicInput" style={{ cursor: "pointer" }}>
                                                            <span style={{ fontWeight: "500" }}>Change Photo</span>
                                                        </label>
                                                        <input type="file" accept="image/*,.gif" id="profilePicInput" onChange={handleFileChange} style={{ display: "none" }} />
                                                        {selectedFile && (
                                                            <button onClick={handleUpload} className="upload-button">
                                                                Upload
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="scrollable-content">
                                                    <div className="portfolio-details">
                                                        {renderDetailItem('Nickname', 'nickname')}
                                                        {renderDetailItem('Username', 'username')}
                                                        {renderDetailItem('Gender', 'gender')}
                                                        {renderDetailItem('Birthday', 'dob', 'date')}
                                                        {renderDetailItem('Bio', 'bio')}
                                                        {renderDetailItem('Country', 'country')}
                                                        {renderDetailItem('Emotion State', 'emotionState')}
                                                        {renderDetailItem('Making Friend Intention', 'friendIntention')}
                                                        {renderDetailItem('Occupation', 'occupation')}
                                                        {renderDetailItem('Mastery of language', 'languageMastery')}
                                                        {renderDetailItem('Hobbies', 'hobbies')}
                                                        {renderDetailItem('Height', 'person_height')}
                                                        {renderDetailItem('Weight', 'person_weight')}
                                                        {renderDetailItem('Instagram', 'instagram')}
                                                        {renderDetailItem('Youtube', 'you_tube')}
                                                        {renderDetailItem('Facebook', 'facebook')}
                                                        {renderDetailItem('Twitter', 'twitter')}
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal> */}
                                        <div className="bio">{userInfo?.user?.bio}</div>

                                    </div>
                                </div>
                                <div className="profile-stats">
                                    <div className="stat"><span>{userInfo?.user?.Following.length}</span> <span onClick={() => { handleOpen(); handleTabChange('Following'); }}>Following</span></div>
                                    <div className="stat"><span>{userInfo?.user?.Followers.length}</span> <span onClick={() => { handleOpen(); handleTabChange('Followers'); }} >Followers</span></div>
                                    <div className="stat"><span>{userInfo?.liked_video?.length}</span> <span style={{ textDecoration: "none" }}>Likes</span></div>

                                    <Modal isOpen={/* active */ false} onRequestClose={handleClose} style={customStyles}>
                                        <div>
                                            <div className="fixed-header">
                                                <div style={{ fontSize: "25px", fontStyle: "bold", display: "flex", justifyContent: "space-between" }}>
                                                    {userInfo?.user?.nickname} <IoClose onClick={handleClose} />
                                                </div>
                                                <div className="profile-stats">
                                                    <div className="stat">
                                                        <span>{/* formatNumber(userInfo?.user?.Following.length) */}</span>
                                                        <span
                                                            onClick={() => handleTabChange('Following')}
                                                            style={{
                                                                textDecoration: /* currentTab === 'Following' */ false ? 'underline' : 'none',
                                                                cursor: 'pointer',
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            Following
                                                        </span>
                                                    </div>
                                                    <div className="stat">
                                                        <span>{/* formatNumber(userInfo?.user?.Followers.length) */}</span>
                                                        <span
                                                            style={{
                                                                textDecoration: /* currentTab === 'Followers' */ false ? 'underline' : 'none',
                                                                cursor: 'pointer',
                                                                marginLeft: "5px",
                                                            }}
                                                            onClick={() => handleTabChange('Followers')}
                                                        >
                                                            Followers
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fixed-header" style={{ fontSize: "25px", fontStyle: "bold", display: "flex", justifyContent: "space-between" }}>
                                                Liked Videos Users
                                            </div>
                                            <div className="scrollable-content">
                                                {/* {currentTab === 'Followers' && userInfo?.user?.Followers?.map((user, id) => {
                                                    const followerDetail = followersDetails.find(detail => detail.user.id === user.id);
                                                    return (
                                                        <div key={id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", borderBottom: '1px solid #ccc', padding: '10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={followerDetail?.user?.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                                                                    alt={followerDetail?.user?.username}
                                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                                />
                                                                <span style={{ marginLeft: '10px' }}>{followerDetail?.user?.username}</span>
                                                            </div>
                                                            {isFollowing(user.id) ? (
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ccc',
                                                                        color: 'black',
                                                                        padding: '5px 10px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleUnfollowButtonPress(user.id)}
                                                                >
                                                                    Unfollow
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    style={{
                                                                        backgroundColor: 'blue',
                                                                        color: 'white',
                                                                        padding: '5px 10px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleFollowButtonPress(user.id)}
                                                                >
                                                                    Follow
                                                                </button>
                                                            )}
                                                        </div>
                                                    );
                                                })}

                                                {currentTab === 'Following' && userInfo?.user?.Following?.map((user, id) => {
                                                    const followerDetail = followersDetails.find(detail => detail.user.id === user.id);
                                                    return (
                                                        <div key={id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", borderBottom: '1px solid #ccc', padding: '10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={followerDetail?.user?.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                                                                    alt={followerDetail?.user?.username}
                                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                                />
                                                                <span style={{ marginLeft: '10px' }}>{followerDetail?.user?.username}</span>
                                                            </div>
                                                            {isFollowing(user.id) ? (
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ccc',
                                                                        color: 'black',
                                                                        padding: '5px 10px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleUnfollowButtonPress(user.id)}
                                                                >
                                                                    Unfollow
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    style={{
                                                                        backgroundColor: 'blue',
                                                                        color: 'white',
                                                                        padding: '5px 10px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleFollowButtonPress(user.id)}
                                                                >
                                                                    Follow
                                                                </button>
                                                            )}
                                                        </div>
                                                    );
                                                })} */}
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <div className="content-category-buttons">
                                    <button className={`tab ${currentVideoTab === 'Videos' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Videos')}><GiHamburgerMenu style={{}} />
                                        Videos</button>
                                    <button className={`tab ${currentVideoTab === 'Like' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Like')}><FaRegHeart style={{}} />
                                        Like</button>
                                    {/* <button className={`tab ${currentVideoTab === 'Favorite' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Favorite')}><FaRegBookmark style={{}} />
                                        Favorite</button>
                                    <button className={`tab ${currentVideoTab === 'Picture' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Picture')}><ImLoop style={{}} />
                                        Picture</button>
                                    <button className={`tab ${currentVideoTab === 'Private' ? 'active' : ''}`} onClick={() => handleVideoTabChange('Private')}><MdLockOutline style={{ fontWeight: "700", fontSize: "23px" }} />
                                        Private</button> */}

                                </div>
                            </div>
                            <div>
                                {currentVideoTab === 'Videos' && (
                                    <div className="video-cards">
                                        <Grid
                                            columnCount={4} // Number of columns in the grid
                                            columnWidth={270} // Width of each column
                                            height={800} // Height of the grid
                                            rowCount={Math.ceil(userInfo?.user?.videos?.length / 4)} // Number of rows
                                            rowHeight={365} // Height of each row
                                            width={1080} // Fixed width for the grid (must be a number)
                                        >
                                            {({ columnIndex, rowIndex, style }) => {
                                                const videoIndex = rowIndex * 4 + columnIndex;
                                                const video = userInfo?.user?.videos[videoIndex];
                                                //userPersonalInfo?.user?.videos
                                                // console.log("privateVideo,", { video })
                                                return <Row columnIndex={columnIndex} rowIndex={rowIndex} style={style} video={video} />;
                                            }}
                                        </Grid>
                                    </div>
                                )}

                                {currentVideoTab === 'Like' && userInfo?.liked_video && (
                                    <div className="video-cards">
                                        <Grid
                                            columnCount={4} // Number of columns in the grid
                                            columnWidth={270} // Width of each column
                                            height={800} // Height of the grid
                                            rowCount={Math.ceil(userInfo?.liked_video?.length / 4)} // Number of rows
                                            rowHeight={365} // Height of each row
                                            width={1080} // Fixed width for the grid (must be a number)
                                        >
                                            {({ columnIndex, rowIndex, style }) => {
                                                const videoIndex = rowIndex * 4 + columnIndex;
                                                const video = userInfo?.liked_video[videoIndex]?.video;
                                                return <Row columnIndex={columnIndex} rowIndex={rowIndex} style={style} video={video} />;
                                            }}
                                        </Grid>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='profile-layout-right-container'></div>
            </div>
        </div>
    );
};

export default UserProfile;
