import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { SERVER_API_URL } from '../../server/server';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import Sidebar from '../../components/sidebar/sidebar';
import RightSidebar from '../../components/rightSidebar/rightSidebar';

import './index.css';

const SingleVideo = () => {
    const { video_id } = useParams();
    const [videos, setVideos] = useState('');
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false)

    console.log('videos', videos)
    console.log("video_id", video_id)

    const fetchVideos = async (video_id) => {
        setLoading(true);
        try {
            const url = `${SERVER_API_URL}/videos/getVideoByVideoId/${video_id}`;
            const response = await axios.get(url);
            const videoUrl = response.data.payload[0]?.video;
            console.log('videoUrl', response)
            setVideos(videoUrl);
        } catch (error) {
            console.error('There was an error fetching the videos!', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (video_id) {
            fetchVideos(video_id);
        }
    }, [video_id]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className='layout-bg-container'>
            <div className='layout-main-container'>
                <div className='layout-sidebar'>
                    <Sidebar />
                </div>
                <div className='layout-middle-container'>
                    <div className='home-bg-main-container'>
                        <div className='video-main-container'>
                            {loading ? (
                                <div className='loading-indicator'>Loading...</div>
                            ) : (
                                (
                                    <div className='video-container'>
                                        <div className='video-wrapper'>
                                            <video key={video_id} controls>
                                                <source src={`https://dpcst9y3un003.cloudfront.net/${videos}`} type='video/mp4'/>
                                            </video>
                                            <div className='video-title'>
                                                {/* Optionally, add title or description here */}
                                            </div>
                                            <div className='video-button-container'>
                                                <button type='button' className='video-button' onClick={() => setPage(page - 1)}>
                                                    <IoIosArrowUp />
                                                </button>
                                                <button type='button' className='video-button' onClick={() => setPage(page + 1)}>
                                                    <IoIosArrowDown />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className='Right-sidebar-container'>
                    {video_id && (
                        <RightSidebar
                            key={video_id}
                            videoId={video_id}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SingleVideo;
