// // src/slices/searchSlice.js

// // Importing necessary functions from Redux Toolkit
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import {
//     searchDiscoverAudio,
//     searchHashTags,
//     searchDiscoverVideo,
//     searchUser,
//     discoverVideobyview,
//     discoverVideobycoins
// } from '../apis/userApi';

// // Defining an async thunk to fetch search results
// export const fetchSearchResults = createAsyncThunk(
//     'search/fetchSearchResults',
//     async (searchText, { rejectWithValue }) => {
//         try {
//             // Fetching search results from different APIs
//             const response = await searchUser(searchText);
//             const hashtagResponse = await searchHashTags(searchText);
//             const videoResponse = await searchDiscoverVideo(searchText, 1, 10);
//             const audioResponse = await searchDiscoverAudio(searchText, 1, 10);
//             const topVideoResponse = await discoverVideobyview(searchText, 1, 10);
//             const topCoinsVideoResponse = await discoverVideobycoins(searchText, 1, 10);

//             // Returning the fetched data
//             return {
//                 users: response || [],
//                 hashtags: hashtagResponse?.payload || [],
//                 videos: videoResponse?.payload || [],
//                 audios: audioResponse?.payload || [],
//                 topVideos: topVideoResponse?.payload || [],
//                 topCoinsVideos: topCoinsVideoResponse?.payload || [],

//             };
//         } catch (error) {
//             // Handling errors
//             return rejectWithValue(error.response.data);
//         }
//     }
// );



// // Creating a slice for managing search-related state
// const searchSlice = createSlice({
//     name: 'search', // Name of the slice
//     initialState: {
//         users: [],
//         hashtags: [],
//         videos: [],
//         topVideos: [],
//         topCoinsVideos: [],
//         audios: [],
//         loading: false,
//         error: null,
//         history: [], // Added history to manage search history
//     },
//     reducers: {
//         // A reducer to set search history 
//         // Version1
//         setUserDetails(state, action) {
//             state.history = [action.payload, ...state.history.filter(item => item !== action.payload)].slice(0, 5);
//         },
//         //Version2
//         // setUserDetails(state, action) {
//         //     const newItem = { term: action.payload, timestamp: Date.now() };
//         //     state.history = [newItem, ...state.history.filter(item => item.term !== action.payload)].slice(0, 5);
//         // },
//     },
//     extraReducers: (builder) => {
//         // Handling different states of the fetchSearchResults async thunk
//         builder
//             .addCase(fetchSearchResults.pending, (state) => {
//                 state.loading = true;
//                 state.error = null;
//             })
//             .addCase(fetchSearchResults.fulfilled, (state, action) => {
//                 state.users = action.payload.users;
//                 state.hashtags = action.payload.hashtags;
//                 state.videos = action.payload.videos;
//                 state.audios = action.payload.audios;
//                 state.topVideos = action.payload.topVideos;
//                 state.topCoinsVideos = action.payload.topCoinsVideos;
//                 state.loading = false;
//             })
//             .addCase(fetchSearchResults.rejected, (state, action) => {
//                 state.loading = false;
//                 state.error = action.payload || 'Failed to fetch search results';
//             });
//     },
// });

// // Exporting actions and the reducer
// export const { setSearchHistory } = searchSlice.actions;
// export default searchSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    searchDiscoverAudio,
    searchHashTags,
    searchDiscoverVideo,
    searchUser,
    discoverVideobyview,
    discoverVideobycoins
} from '../apis/userApi';

// Defining an async thunk to fetch search results
export const fetchSearchResults = createAsyncThunk(
    'search/fetchSearchResults',
    async (searchText, { dispatch, rejectWithValue }) => {
        try {
            // Fetch user results first
            const userResponse = await searchUser(searchText);
            dispatch(setUserResults(userResponse || [])); // Dispatch user results

            // Fetch remaining results
            const hashtagResponse = await searchHashTags(searchText);
            const videoResponse = await searchDiscoverVideo(searchText, 1, 10);
            const audioResponse = await searchDiscoverAudio(searchText, 1, 10);
            const topVideoResponse = await discoverVideobyview(searchText, 1, 10);
            const topCoinsVideoResponse = await discoverVideobycoins(searchText, 1, 10);

            // Returning the fetched data for the remaining results
            return {
                hashtags: hashtagResponse?.payload || [],
                videos: videoResponse?.payload || [],
                audios: audioResponse?.payload || [],
                topVideos: topVideoResponse?.payload || [],
                topCoinsVideos: topCoinsVideoResponse?.payload || [],
            };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Creating a slice for managing search-related state
const searchSlice = createSlice({
    name: 'search',
    initialState: {
        users: [],
        hashtags: [],
        videos: [],
        topVideos: [],
        topCoinsVideos: [],
        audios: [],
        loading: false,
        error: null,
        history: [],
    },
    reducers: {
        setUserResults(state, action) {
            state.users = action.payload;
        },
        setUserDetails(state, action) {
            state.history = [action.payload, ...state.history.filter(item => item !== action.payload)].slice(0, 5);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchResults.pending, (state) => {
                // state.loading = true;
                state.error = null;
            })
            .addCase(fetchSearchResults.fulfilled, (state, action) => {
                state.hashtags = action.payload.hashtags;
                state.videos = action.payload.videos;
                state.audios = action.payload.audios;
                state.topVideos = action.payload.topVideos;
                state.topCoinsVideos = action.payload.topCoinsVideos;
                // state.loading = false;
            })
            .addCase(fetchSearchResults.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch search results';
            });
    },
});

export const { setUserResults, setUserDetails } = searchSlice.actions;
export default searchSlice.reducer;
