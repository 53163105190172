import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { SERVER_API_URL } from '../../server/server';
import { useNavigate } from 'react-router-dom';
// import demo from '../../Assets/video/demo_video2.mp4'
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { FaRegHeart, FaRegCommentDots, FaHeart, FaShare, FaInstagram } from 'react-icons/fa';
import { BsSnapchat } from "react-icons/bs";
import { AiFillTikTok } from "react-icons/ai";
import { MdOutlineSaveAlt } from "react-icons/md";
// import { PiDotsThreeOutlineThin } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
} from "react-share";
import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    TelegramIcon,
} from "react-share";
import Comment from '../Comment';

import './rightSidebar.css';

const suggestedUsers = [
    { id: 1, name: 'xxØrem☆彡', handle: 'mr_pr_em9', profilePic: 'profile1.jpg' },
    { id: 2, name: 'Anita Kumari', handle: 'anita_kumari_002002', profilePic: 'profile2.jpg' },
    { id: 3, name: 'aryan_singh_rajput', handle: 'aryan_singh_rajput755', profilePic: 'profile3.jpg' },
    { id: 4, name: 'satyam raj', handle: 'satyam43706', profilePic: 'profile4.jpg' },
    { id: 5, name: 'Ragni Kumari', handle: 'rma.525', profilePic: 'profile5.jpg' }
];



const RightSidebar = ({ videoId }) => {
    const history = useNavigate();
    const [comments, setComments] = useState([]);
    const [shares, setShares] = useState({ top: 99300, bottom: 4712 });
    const [activeComment, setActiveComment] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [likesData, setLikesData] = useState([]);
    const [num_like, setNum_like] = useState(likesData);
    const [isLike, setIsLike] = useState(false);
    const [commentData, setCommentData] = useState('');
    const [showShareOptions, setShowShareOptions] = useState(false); // State to toggle share options visibility
    const [videoUrl, setVideoUrl] = useState(''); // State to store the video URL
    const [profile_pic, setProfilePic] = useState('');
    const [reciever_id, setReciever_id] = useState('');
    const [video_id, setVideo_id] = useState('')
    const [downlodVideo, setDownloadVideo] = useState('')
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [followList, setFollowList] = useState('')
    const [shareWhatsappLink, setShareWhatsappLink] = useState('')
    const [shareFacebookLink, setShareFacebookLink] = useState('')
    const [shareTwitterLink, setShareTwitterLink] = useState('')
    const [shareLinkedinLink, setShareLinkedinLink] = useState('')
    const [shareTelegramLink, setShareTelegramLink] = useState('')
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleCheckboxChange = (id) => {
        setSelectedUsers((prevSelectedUsers) =>
            prevSelectedUsers.includes(id)
                ? prevSelectedUsers.filter((userId) => userId !== id)
                : [...prevSelectedUsers, id]
        );
    };

    // console.log("video_id", video_id)
    // console.log("shareLink", shareWhatsappLink)

    useEffect(() => {
        const storedUserDetails = localStorage.getItem('userDetails');
        if (storedUserDetails) {
            try {
                setUserDetails(JSON.parse(storedUserDetails));
            } catch (error) {
                console.error("Error parsing user details from localStorage:", error);
            }
        } else {
            setUserDetails(null); // Ensure userDetails is set to null if nothing is found
        }
    }, []);

    const handleLogout = () => {
        // Remove userDetails from localStorage
        localStorage.removeItem('userDetails');
        // Reset userDetails state
        setUserDetails(null);
    };


    useEffect(() => {
        if (userDetails || videoId) {
            fetchComments(videoId);
            fetchVideosbyid(videoId);
        }
    }, [videoId, userDetails]);

    useEffect(() => {
        const isIdPresent = likesData.some(item => item?.sender_id === userDetails?.id);
        setIsLike(isIdPresent);
        setNum_like(likesData.length);
    }, [likesData]);

    const fetchComments = async (postId) => {
        if (!postId) {
            console.error("Invalid postId:", postId);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(`https://api.dreamlived.com/comments/fetchComment/${postId}`);
            setComments(response.data.comments);
        } catch (error) {
            console.error("There was an error fetching the comments!", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchVideosbyid = async (videoId) => {
        try {
            const url = `${SERVER_API_URL}/videos/getVideoByVideoId/${videoId}`;
            const response = await axios.get(url)
            setLikesData(response.data.payload[0].likes)
            setProfilePic(response.data.payload[0].user.profile_pic)
            setReciever_id(response.data.payload[0].user_id)
            setThumbnailUrl(response.data.payload[0].thum)
            setVideo_id(response.data.payload[0]?.id)
            console.log("first1111", response.data.payload[0])
            setVideoUrl(`https://dreamlived.com/video/${videoId}`)
            setDownloadVideo(`https://dpcst9y3un003.cloudfront.net/${response.data.payload[0].video}`)
            // setVideoUrl('https://example.com/video123'); 
        } catch (error) {
            console.error('There was an error fetching the videos!', error);
        }
    }

    const handleComment = () => setActiveComment(!activeComment);
    const handleShare = () => setShares({ top: shares.top + 1, bottom: shares.bottom + 1 });

    const handleScrollToTop = () => {
        const commentSection = document.querySelector(".comment-section");
        if (commentSection) {
            commentSection.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    const handleLike = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            history('/auth', {replace:true})
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: videoId,
            reciever_id,
            unlike: isLike,
        };

        setIsLike(!isLike);
        setNum_like(isLike ? num_like - 1 : num_like + 1);

        try {
            const url = `https://api.dreamlived.com/likes/video_like`;
            await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });
        } catch (error) {
            console.error("Error updating like status:", error);
        }
    };

    const handleCommentSubmit = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            history('/auth', {replace:true})
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: videoId,
            comment_data: commentData,
        };

        try {
            const url = `https://api.dreamlived.com/comments/createComment`;
            await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });
            setCommentData('');
            fetchComments(videoId); // Ensure comments are re-fetched after creating a new comment
        } catch (error) {
            console.error("Error submitting comment:", error);
        }
    };

    const handelDownload = (videoUrl) => {
        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = videoUrl.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleOpenInstagramDM = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: reciever_id,
            user_id: video_id,
            sharing_platform: "instagram",
            sharing_from: "web"
        };

        try {
            const url = `https://2115.api.dreamlived.com/share/addVideoShare`;
            const result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });

            await navigator.clipboard.writeText(result.data.video_share_link).then(() => {
                window.open('https://www.instagram.com/direct/inbox/', '_blank');
            }).catch((err) => {
                console.error('Failed to copy text: ', err);
            });
        } catch (error) {
            console.error("Error updating like status:", error);
        }
    };

    const handleOpenTicTokDM = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: reciever_id,
            user_id: video_id,
            sharing_platform: "tiktok",
            sharing_from: "web"
        };

        try {
            const url = `https://2115.api.dreamlived.com/share/addVideoShare`;
            const result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });

            await navigator.clipboard.writeText(result.data.video_share_link).then(() => {
                window.open('https://www.tiktok.com/inbox', '_blank');
            }).catch((err) => {
                console.error('Failed to copy text: ', err);
            });
        } catch (error) {
            console.error("Error updating like status:", error);
        }
    };

    const handleOpenSnapchatDM = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: reciever_id,
            user_id: video_id,
            sharing_platform: "snapchat",
            sharing_from: "web"
        };

        try {
            const url = `https://2115.api.dreamlived.com/share/addVideoShare`;
            const result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });

            await navigator.clipboard.writeText(result.data.video_share_link).then(() => {
                window.open('https://www.snapchat.com/add', '_blank');
            }).catch((err) => {
                console.error('Failed to copy text: ', err);
            });
        } catch (error) {
            console.error("Error updating like status:", error);
        }


    };

    const WhatsappShare = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: reciever_id,
            user_id: video_id,
            sharing_platform: "whatsapp",
            sharing_from: "web"
        };

        try {
            const url = `https://2115.api.dreamlived.com/share/addVideoShare`;
            const result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });
            setShareWhatsappLink(result.data.video_share_link)
            console.log("result", result)
            console.log("reciever_id", reciever_id)

        } catch (error) {
            console.error("Error updating like status:", error);
        }
    }

    const FacebookShare = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: reciever_id,
            user_id: video_id,
            sharing_platform: "facebook",
            sharing_from: "web"
        };

        try {
            const url = `https://2115.api.dreamlived.com/share/addVideoShare`;
            const result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });
            setShareFacebookLink(result.data.video_share_link)
            console.log("result", result)
            console.log("reciever_id", reciever_id)

        } catch (error) {
            console.error("Error updating like status:", error);
        }
    }

    const TwitterShare = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: reciever_id,
            user_id: video_id,
            sharing_platform: "twitter",
            sharing_from: "web"
        };

        try {
            const url = `https://2115.api.dreamlived.com/share/addVideoShare`;
            const result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });
            setShareTwitterLink(result.data.video_share_link)
            console.log("result", result)
            console.log("reciever_id", reciever_id)

        } catch (error) {
            console.error("Error updating like status:", error);
        }
    }

    const LinkedinShare = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: reciever_id,
            user_id: video_id,
            sharing_platform: "linkedin",
            sharing_from: "web"
        };

        try {
            const url = `https://2115.api.dreamlived.com/share/addVideoShare`;
            const result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });
            setShareLinkedinLink(result.data.video_share_link)
            console.log("result", result)
            console.log("reciever_id", reciever_id)

        } catch (error) {
            console.error("Error updating like status:", error);
        }
    }


    const TelegramShare = async () => {
        if (!userDetails) {
            console.error('User details not found.');
            return;
        }

        const { auth_token } = userDetails;
        if (!auth_token) {
            console.error('Token is missing.');
            return;
        }

        const data = {
            video_id: reciever_id,
            user_id: video_id,
            sharing_platform: "telegram",
            sharing_from: "web"
        };

        try {
            const url = `https://2115.api.dreamlived.com/share/addVideoShare`;
            const result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth_token}`
                }
            });
            setShareTelegramLink(result.data.video_share_link)
            console.log("result", result)
            console.log("reciever_id", reciever_id)

        } catch (error) {
            console.error("Error updating like status:", error);
        }
    }

    const handleOpenfollowList = () => {
        setFollowList(!followList)
    }

    const handleClose = () => {
        setShowShareOptions(false)
    }

    const handleShareMenu = () => {
        setIsButtonDisabled(true)
        setShowShareOptions(true)
        WhatsappShare()
        FacebookShare()
        TwitterShare()
        LinkedinShare()
        TelegramShare()
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 3000);
    }


    return (
        <div className="rightSidebar">
            <div className='header_button'>
                <button className="loginButton">Profile</button>
                <button className="loginButton" onClick={handleLogout}>Log out</button>
                <Link to="/auth">
                    <button className="uploadButton">Login</button>
                </Link>
            </div>
            {loading ? (
                <div className='loading-indicator'>Loading...</div>
            ) : (
                activeComment ? (
                    <div className="comment-section">
                        <div className="back-to-top" onClick={handleComment}>Back</div>
                        {comments.length > 0 ? (
                            <>
                                {comments.map((comment) => (
                                    <Comment key={comment.id} comment={comment} videoId={videoId} fetchComments={fetchComments} />
                                ))}
                                <textarea
                                    type="text"
                                    rows={4}
                                    value={commentData}
                                    onChange={(e) => setCommentData(e.target.value)}
                                    placeholder="Write a comment..."
                                    className="reply-input"
                                    style={{ width: "96%", marginTop: "1px" }}
                                />
                                <button type="button" className="reply-button" style={{ marginLeft: "68%" }}
                                    onClick={handleCommentSubmit}>send</button>
                            </>
                        ) : (
                            <>
                                <div style={{ textAlign: "center", color: "#ddd", fontSize: "20px", fontWeight: "800", marginTop: "60%", height: "auto", padding: "20px" }}>There are no comments for this video</div>
                                <textarea
                                    type="text"
                                    rows={4}
                                    value={commentData}
                                    onChange={(e) => setCommentData(e.target.value)}
                                    placeholder="Write a comment..."
                                    className="reply-input"
                                    style={{ width: "96%", marginTop: "39vh" }}
                                />
                                <button type="button" className="reply-button" style={{ marginLeft: "66%" }}
                                    onClick={handleCommentSubmit}>
                                    send
                                </button>
                            </>
                        )}
                        <div className="back-to-top-down" onClick={handleScrollToTop}>
                            <MdKeyboardDoubleArrowUp size={30} />
                        </div>
                    </div>
                ) : (
                    <div className='interaction_container'>
                        <div className="profile">
                            <img src={profile_pic} alt="user avatar" className="avatar" />
                        </div>
                        <div className='interaction-main-container'>
                            <div className="interactions">
                                <button onClick={handleLike}>
                                    {isLike ? <FaHeart style={{ color: 'red' }} /> : <FaRegHeart />}
                                </button>
                                <span>{num_like}</span>

                                <button onClick={handleComment}>
                                    <FaRegCommentDots />
                                </button>
                                <div className='comments-length'>{comments.length}</div>

                                <button onClick={() => handelDownload(downlodVideo)}>
                                    <MdOutlineSaveAlt />
                                </button>
                                <span>{shares.top.toLocaleString()}</span>

                                <button type='button' onClick={handleShareMenu} disabled={isButtonDisabled}>
                                    {isButtonDisabled ? <FaShare color='#aebdb2' /> : <FaShare />}
                                </button>
                                <span>{shares.top.toLocaleString()}</span>
                            </div>
                            {showShareOptions && (
                                <div className='share-button-container'>

                                    <RxCross2 className='close-icon' style={{ alignSelf: "flex-end" }} color='red' size={30} onClick={handleClose} />

                                    <div className='share-button'>
                                        {/* Share buttons */}

                                        {shareFacebookLink && (
                                            <FacebookShareButton url={shareFacebookLink} quote={'Check out this amazing video!'}>
                                                <FacebookIcon size={32} round={true} />
                                            </FacebookShareButton>
                                        )}

                                        {shareTwitterLink && (
                                            <TwitterShareButton url={shareTwitterLink} title={'Check out this amazing video!'}>
                                                <TwitterIcon size={32} round={true} />
                                            </TwitterShareButton>
                                        )}

                                        {shareLinkedinLink && (
                                            <LinkedinShareButton url={shareLinkedinLink} title={'Check out this amazing video!'}>
                                                <LinkedinIcon size={32} round={true} />
                                            </LinkedinShareButton>
                                        )}

                                        {shareWhatsappLink && (
                                            <WhatsappShareButton url={shareWhatsappLink}>
                                                <WhatsappIcon size={32} round={true} />
                                            </WhatsappShareButton>
                                        )}

                                        {shareTelegramLink && (
                                            <TelegramShareButton url={shareTelegramLink} title={'Check out this amazing video!'}>
                                                <TelegramIcon size={32} round={true} />
                                            </TelegramShareButton>
                                        )}

                                        <div className="share-option" onClick={handleOpenInstagramDM}>
                                            <FaInstagram size={32} color='red' round={true} />
                                        </div>

                                        <div className="share-option" onClick={handleOpenTicTokDM}>
                                            <AiFillTikTok size={32} round={true} />
                                        </div>

                                        <div className="share-option" onClick={handleOpenSnapchatDM}>
                                            <BsSnapchat size={26} round={true} style={{ paddingLeft: "10px", paddingTop: "4px" }} />
                                        </div>

                                        <div className="share-option">
                                            <BsFillArrowRightSquareFill onClick={handleOpenfollowList} size={26} round={true} style={{ paddingLeft: "10px", paddingTop: "4px" }} />
                                        </div>
                                    </div>
                                    {followList && (
                                        <div className="share-container">
                                            <div className="share-header">
                                                <input type="text" placeholder="Search..." className="search-input" />
                                                <button className="close-button">&times;</button>
                                            </div>
                                            <div className="suggested-list">
                                                {suggestedUsers.map((user) => (
                                                    <div className="suggested-item" key={user.id}>
                                                        <img src={user.profilePic} alt="Profile" className="profile-pic" />
                                                        <div className="user-info">
                                                            <span className="username">{user.name}</span>
                                                            <span className="handle">@{user.handle}</span>
                                                        </div>
                                                        
                                                        <input
                                                            type="checkbox"
                                                            className="select-user"
                                                            checked={selectedUsers.includes(user.id)}
                                                            onChange={() => handleCheckboxChange(user.id)}
                                                        />

                                                    </div>
                                                ))}
                                               
                                            </div>
                                            <button className="send-button">Send</button>
                                        </div>
                                    )}

                                </div>
                            )}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default RightSidebar;