import React from "react";
import "./sideElement.css";

export default function SideElement({ Icon, text, onClick }) {
    return (
        <div className="container" onClick={onClick} style={{ cursor: "pointer" }}>
            <Icon className="icon" />
            <span className="text">{text}</span>
        </div>
    )
}