import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SERVER_API_URL } from '../../server/server';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
// import video from '../../Assets/video/demo_video1.mp4'

import Sidebar from '../../components/sidebar/sidebar';
import RightSidebar from '../../components/rightSidebar/rightSidebar';

import './index.css';

const Home = () => {
    const [userAllVideos, setUserAllVideos] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize] = useState(5);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    // Fetch videos from the server
    const fetchVideos = async (page) => {
        setLoading(true);
        try {
            const url = `${SERVER_API_URL}/videos/userAllVideos?page=${page}&pageSize=${pageSize}`;
            const response = await axios.get(url);
            setUserAllVideos((prevVideos) => [...prevVideos, ...response.data.videos]);
        } catch (error) {
            console.error('There was an error fetching the videos!', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch videos when the component mounts and when the page changes
    useEffect(() => {
        fetchVideos(page);
    }, [page]);

    // Handle scrolling to load more videos
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Move to the previous video
    const handleUp = () => {
        if (currentVideoIndex > 0) {
            setCurrentVideoIndex((prevIndex) => prevIndex - 1);
        }
    };

    // Move to the next video
    const handleDown = () => {
        if (currentVideoIndex < userAllVideos.length - 1) {
            setCurrentVideoIndex((prevIndex) => prevIndex + 1);
        }
    };

    return (
        <div className='layout-bg-container'>
            <div className='layout-main-container'>
                {/* Sidebar container */}
                <div className='layout-sidebar'>
                    <Sidebar />
                </div>
                {/* Middle container */}
                <div className='layout-middle-container'>
                    <div className='home-bg-main-container'>
                        <div className='video-main-container'>
                            {loading && <div className='loading-indicator'>Loading...</div>}
                            {userAllVideos.length > 0 && currentVideoIndex < userAllVideos.length && (
                                <div className='video-container'>
                                    <div className='video-wrapper'>
                                        <video
                                            key={userAllVideos[currentVideoIndex].id}
                                            controls
                                            autoPlay
                                            loop
                                            muted
                                        >
                                            <source
                                                src={`https://dpcst9y3un003.cloudfront.net/${userAllVideos[currentVideoIndex].video}`}
                                                type='video/mp4'
                                            />
                                             {/* <source
                                                src={video}
                                                type='video/mp4'
                                            /> */}
                                        </video>
                                        <div className='video-title'>
                                            <span className='video-text'>{userAllVideos[currentVideoIndex].title}</span>
                                            <span className='video-text'>{userAllVideos[currentVideoIndex].description}</span>
                                        </div>
                                        <div className='video-button-container'>
                                            <button type='button' className='video-button' onClick={handleUp}>
                                                <IoIosArrowUp />
                                            </button>
                                            <button type='button' className='video-button' onClick={handleDown}>
                                                <IoIosArrowDown />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* Right sidebar container */}
                <div className='Right-sidebar-container'>
                    {userAllVideos.length > 0 && currentVideoIndex < userAllVideos.length && (
                        <RightSidebar
                            key={userAllVideos[currentVideoIndex].id}
                            videoId={userAllVideos[currentVideoIndex].id}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;