// src/pages/AuthPage.js
import React, { useState } from 'react';
import Modal from "react-modal";
import { MdOutlineEmail, MdLockOutline } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaRegUser } from "react-icons/fa";
import { useNavigate, Link } from 'react-router-dom';
import './index.css';

const AuthPage = () => {
    const [state, setState] = useState("logIn");
    const navigate = useNavigate();

    const handleState = () => {
        setState(state === "logIn" ? "signUp" : "logIn");
    };

    const handleClose = () => {
        navigate('/');
    };

    const customStyles = {
        content: {
            width: "30%",
            height: "80%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            transition: "opacity 2000ms ease-in-out",
        },
    };

    return (
        <div className="auth-page">
            <Modal isOpen={true} onRequestClose={handleClose} style={customStyles}>
                {state === "logIn" ? (
                    <div className="login-modal-style">
                        <h1 style={{ marginBottom: "-25px" }}>Dream</h1>
                        <h2 style={{ marginBottom: "-5px", color: "grey", fontWeight: "500" }}>Log in with email and password.</h2>
                        <div className="input-wrapper">
                            <MdOutlineEmail className="login-modal-icon" />
                            <input placeholder='Email' type="email" className='login-modal-style-input' />
                        </div>
                        <div className="input-wrapper">
                            <MdLockOutline className="login-modal-icon" />
                            <input placeholder='Password' type="password" className='login-modal-style-input' />
                        </div>
                        <button className='login-modal-style-btn' style={{ backgroundColor: "rgb(254, 44, 85)", color: "white", border: "none" }}>Log in</button>
                        <div className='login-modal-style-btn'>Forget password</div>

                        <span className='signUp-modal-terms'>
                            By continuing, you agree to Dream’s Terms of Service and confirm that you have read Dream’s Privacy Policy.
                        </span>

                        <div className="login-modal-signup" style={{ position: "absolute", bottom: "40px" }}>
                            Don't have an account?
                            <span style={{ cursor: "pointer", color: "rgb(254, 44, 85" }} onClick={handleState}> Sign Up</span>
                        </div>
                    </div>
                ) : (
                    <div className="signUp-modal-style">
                        <h1 style={{ marginBottom: "-25px" }}>Dream</h1>
                        <h2 style={{ marginBottom: "-5px", color: "grey", fontWeight: "500" }}>Sign Up with email and password.</h2>
                        <div className='login-modal-style-btn'><FaRegUser style={{ position: "absolute", left: "10px", fontSize: "20" }} />
                            Use phone or email</div>
                        <div className='login-modal-style-btn'><FaFacebook style={{ position: "absolute", left: "10px", color: "#1877f2", fontSize: "20" }} />
                            Continue with Facebook</div>
                        <div className='login-modal-style-btn' ><FcGoogle style={{ position: "absolute", left: "10px", fontSize: "20" }} />
                            Continue with Google</div>
                        <span className='signUp-modal-terms'>
                            By continuing, you agree to Dream’s Terms of Service and confirm that you have read Dream’s Privacy Policy.
                        </span>
                        <div style={{ position: "absolute", bottom: "40px" }}>
                            Already have an account?
                            <span style={{ cursor: "pointer", color: "rgb(254, 44, 85", onHover: { textDecoration: "underline" } }} onClick={handleState}> Log In</span>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default AuthPage;
