import React from "react";
import "./index.css";
import { Link } from 'react-router-dom';
import { GoHome } from "react-icons/go";

const conversations = [
    {
        id: 1,
        name: "Alice",
        preview: "Hey, how are you?",
        unread: true,
    },
    {
        id: 2,
        name: "Bob",
        preview: "Can we meet tomorrow?",
        unread: false,
    },
    {
        id: 2,
        name: "Bob",
        preview: "Can we meet tomorrow?",
        unread: false,
    },
    {
        id: 3,
        name: "Charlie",
        preview: "Don't forget to bring the documents.",
        unread: true,
    },
    {
        id: 4,
        name: "Charlie",
        preview: "Don't forget to bring the documents.",
        unread: true,
    },
    {
        id: 4,
        name: "Charlie",
        preview: "Don't forget to bring the documents.",
        unread: true,
    },
    {
        id: 4,
        name: "Charlie",
        preview: "Don't forget to bring the documents.",
        unread: true,
    },
    {
        id: 4,
        name: "Charlie",
        preview: "Don't forget to bring the documents.",
        unread: true,
    },
    {
        id: 4,
        name: "Charlie",
        preview: "Don't forget to bring the documents.",
        unread: true,
    }
];

const selectedChat = {
    username: "Alice",
    violation: false,
    messages: [
        { id: 1, text: "See you soon!", restricted: true },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 2, text: "I'm fine, thanks for asking!", restricted: false },
        { id: 3, text: "I'm good, thanks! How about you?", restricted: false },
        { id: 4, text: "Hey, how are you?", restricted: false },

    ]
};

const Messages = () => {
    return (
        <div className="chat-page">
            <div className="message-list">
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <span className="message-home-return">
                        <GoHome style={{ fontSize: "25px", fontWeight: "600" }} /> Home
                    </span>
                </Link>
                <div className="message-list-container">
                    <h2>Messages</h2>
                    {conversations.length > 0 ? (
                        conversations.map((conversation) => (
                            <div key={conversation.id} className="conversation-snippet">
                                {/* <img src="path-to-tiktok-logo.png" alt="TikTok Logo" className="tiktok-logo" /> */}
                                <img src="https://assets.monica.im/tools-web/_next/static/media/imageGeneratorFeatureIntro1.9f5e7e23.webp" alt="user avatar" className="messages-profile-avatar" />
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                                    <span>{conversation.name}</span>
                                    <span>{conversation.preview}</span>
                                </div>
                                {/* {conversation.unread && <span className="unread-indicator"></span>} */}
                            </div>
                        ))
                    ) : (
                        <p className="no-content">No conversations available</p>
                    )}
                </div>

            </div>

            <div className="chat-panel">
                <span className="chat-user-section"><img src="https://assets.monica.im/tools-web/_next/static/media/imageGeneratorFeatureIntro1.9f5e7e23.webp" alt="user avatar" className="messages-profile-avatar" />
                    <span>@{selectedChat.username}</span></span>
                <div className="chat-header">
                    {selectedChat.violation && (
                        <span className="warning-icon">Community guideline violation</span>
                    )}
                </div>
                <div className="chat-messages-section">
                    {selectedChat.messages && selectedChat.messages.length > 0 ? (
                        selectedChat.messages.map((msg) => (
                            <div key={msg.id} className={`chat-bubble ${msg.restricted ? 'restricted' : ''}`}>
                                {msg.text}
                                {msg.restricted && <span className="error-icon">!</span>}
                            </div>
                        ))
                    ) : (
                        <p className="no-content">No messages available</p>
                    )}
                </div>
                <div className="message-input">
                    <input type="text" placeholder="Send a message..." />
                </div>
            </div>
        </div>
    );
};

export default Messages;
