import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import './index.css';
import {
    getfavouritehashtag,
    getFavouriteSound,
    getFavouriteVideo,
    getUserFavouriteUsers
} from '../../apis/userApi';

import hashtagImg from "../../assets/images/hashtag.png"

const MultiLayout = ({ Img, title, title2, username, audioUrl, videoUrl, thumUrl }) => {

    const audioRef = React.createRef();

    //############################## VIDEO PLAY ###################################//
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    //############################## VIDEO PLAY ###################################//

    return (
        <div className="song-layout-video-thumbnail">
            {/* <div className="song-layout-index-number">{title}</div> */}
            <img
                src={Img}
                alt="Video Thumbnail"
                className="song-layout-thumbnail-image"
                onClick={openModal}
            />
            {/* {
                thumUrl && <img
                    src={thumUrl}
                    alt="Video Thumbnail"
                    className="song-layout-thumbnail-image"
                />
            } */}
            <div className="song-layout-video-details" style={{ marginLeft: "25px", width: "60%" }}>
                <div className="song-layout-video-title">{title}</div>
                {username && <div className="song-layout-video-title">Author: {username}</div>}

                <div className="song-layout-channel-info">
                    {title2}
                    {/* {moreDetail && <> <span style={{ fontWeight: "bold" }}>Views</span> {song.view} <span style={{ fontWeight: "bold" }}>Likes</span> {song.like}</>} */}
                </div>
            </div>
            {audioUrl && (
                < audio ref={audioRef} controls src={`https://dpcst9y3un003.cloudfront.net/extracted_audio/${audioUrl}`}>
                    <source src={`https://dpcst9y3un003.cloudfront.net/${audioUrl}`} type="audio/aac" />
                </audio>
            )}

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Video Modal"
                style={{
                    overlay: {
                        zIndex: 1000,
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
                        transition: "opacity 2000ms ease-in-out",
                    },
                    content: {
                        width: '40%',
                        height: '60%',
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: "none"
                    },
                }}
            >
                <button onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px' }}>Close</button>
                {videoUrl && (
                    <video controls autoPlay style={{ width: '100%', height: '100%' }}>
                        <source src={`https://dpcst9y3un003.cloudfront.net/${videoUrl}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </Modal>

            <div className="song-layout-options-menu">...</div>

        </div>
    );
};



const SongList = () => {

    const [loading, setLoading] = useState(true);

    const [songsData, setSongsData] = useState([]);

    useEffect(() => {
        const fetchSongData = async () => {
            try {
                const data = await getFavouriteSound();
                if (data.success) {
                    setSongsData(data.payload);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching hashtags:', error);
            }
        };

        fetchSongData();
    }, []);


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {songsData.map((item) => (
                <MultiLayout
                    key={item.id}
                    Img={item?.extracted_audio?.user?.profile_pic}
                    username={item?.extracted_audio?.user?.nickname}
                    title2={item?.extracted_audio?.user?.username}
                    audioUrl={item?.extracted_audio?.audio_url}
                />
            ))}
        </div>
    );
};

const HashtagList = () => {
    const [hashtagData, setHashtagData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchHashtags = async () => {
            try {
                const data = await getfavouritehashtag();
                if (data.success) {
                    setHashtagData(data.payload);
                    setLoading(false);

                }
            } catch (error) {
                console.error('Error fetching hashtags:', error);
            }
        };

        fetchHashtags();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {hashtagData.map((item) => (
                <div className="song-layout-video-thumbnail" key={item.id}>
                    {/* <div className="song-layout-index-number">{item.title}</div> */}
                    <div style={{ backgroundColor: "rgba(53, 34, 195, 1)", marginRight: "15px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                        <img
                            // src={`https://instaguru.online/wp-content/uploads/2020/12/heshtegi(3).jpg`}
                            src={hashtagImg}
                            alt="Hashtag Thumbnail"
                            className="song-layout-thumbnail-image"
                            style={{ height: "30px", width: "30px" }}
                        />
                    </div>

                    <div className="song-layout-video-details">
                        <div className="song-layout-video-title">{item.title}</div>
                        <div className="song-layout-channel-info">
                            <span>{`Number of videos:`}
                                <span style={{ fontWeight: "bolder", color: "black" }}> {item.num_of_video}</span>
                            </span>
                        </div>
                    </div>
                    <div className="song-layout-options-menu">...</div>
                </div>))}
        </div>
    );
};


const VideoList = () => {

    const [favVideoData, setFavVideoData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchHashtags = async () => {
            try {
                const data = await getFavouriteVideo();
                if (data.success) {
                    setFavVideoData(data.payload);
                    setLoading(false);

                }
            } catch (error) {
                console.error('Error fetching favVideo:', error);
            }
        };

        fetchHashtags();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {favVideoData.map((item) => (
                <MultiLayout
                    key={item.id}
                    Img={item?.video?.user?.profile_pic}
                    title={item?.video?.user?.nickname}
                    title2={item?.video?.user?.username}
                    videoUrl={item?.video?.video}
                    thumUrl={item?.video?.thum}
                />
            ))}
        </div>
    );
};




const UserList = () => {

    const [favUserData, setFavUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFavUserData = async () => {
            try {
                const data = await getUserFavouriteUsers();
                if (data.success) {
                    setFavUserData(data.payload);
                    setLoading(false);

                }
            } catch (error) {
                console.error('Error fetching favVideo:', error);
            }
        };

        fetchFavUserData();
    }, []);
    // console.log("favUserData", favUserData)


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {favUserData.map((item) => (
                <MultiLayout key={item.id} Img={item?.user?.profile_pic} title={item?.user?.nickname} />
            ))}
        </div>
    );
};


export { SongList, HashtagList, VideoList, UserList };

