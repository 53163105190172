import React, { useState } from "react";
import "./sidebar.css"

//Components
import SideElement from "../sideElements/sideElement"
import ButtonElm from "../buttonElm/buttonElm";
import UserProfile from "../userProfile/userProfile";

//ICONS
import { FaSearch } from 'react-icons/fa'; // import the icon
import { FaRegMessage } from "react-icons/fa6";
import { IoIosSettings } from "react-icons/io";
import { RiUserFollowFill } from "react-icons/ri";
import { GoPerson } from "react-icons/go";
import { GiShoppingCart } from "react-icons/gi";
import { LuLayoutGrid } from "react-icons/lu";

import { Link } from 'react-router-dom';


export default function Sidebar({ onNotifyClick }) {


    return (
        <div className="sidebar_container">
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <img src="https://img.myloview.com/stickers/dream-hand-written-text-word-for-design-can-be-used-for-a-logo-700-272335397.jpg" className="side_logo" alt="image_goes_here"></img>
            </Link>

            <div className="sidebar_elements" >
                <Link to="/search" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <SideElement Icon={FaSearch} text="Search" />
                </Link>
                <SideElement Icon={GiShoppingCart} text="For You" />
                <SideElement Icon={RiUserFollowFill} text="Following" />

                <SideElement Icon={LuLayoutGrid} text="Basket" onClick={onNotifyClick} />


                <Link to="/messages" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <SideElement Icon={FaRegMessage} text="Inbox" />
                </Link>

                {/* <SideElement Icon={FaUserFriends} text="Friends" /> */}
                <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <SideElement Icon={GoPerson} text="Profile" />
                </Link>
                <SideElement Icon={IoIosSettings} text="Settings" />
                {/* <SideElement Icon={CgMoreO} text="More" /> */}
            </div>
            <div className="side_btn">
                <ButtonElm />
            </div>
            <UserProfile />
        </div>

    )
}